import { z } from 'zod';
import { varConditionSchema } from './conditional.zod';
import { localVarCfgSchema } from './local.zod';
import { varpConfigBase } from './var-provider.zod';
import { varNameSchema } from '../var-providers/var.zod';

const customExecutionSchema = z.object({
    type: z.literal('custom'),
    condition: varConditionSchema,
    appletVariables: z.array(z.string()),
});

const defaultExecutionSchema = z.object({
    type: z.literal('default'),
    outputVars: z.array(localVarCfgSchema),
    includedVars: z.array(z.object({
        tracked: z.boolean(),
        name: z.string(),
    })),
});


export const scriptProviderAttrsSchema = z.object({
    scriptId: z.string(),
    executeCfg: z.discriminatedUnion('type', [
        customExecutionSchema,
        defaultExecutionSchema,
    ]),
});

export const scriptProviderSchema = varpConfigBase.extend({
    type: z.literal('script'),
    attrs: scriptProviderAttrsSchema,
    inputs: z.array(varNameSchema),
});
