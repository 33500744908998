/* eslint-disable max-lines */
import { router } from '@redviking/argonaut-core-ui/src/routing/router';
import { actionTree, getStoreType, getterTree, mutationTree } from 'typed-vuex';
import { replacerListUtils } from '@redviking/argonaut-core-ui/src/util/composables/use-computed-list';
import { flowsTabRoute, screensTabRoute, scriptsTabRoute, varsTabRoute } from './util/consts';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { v4 as uuidv4 } from 'uuid';
import { decomposeMacro } from '@redviking/argonaut-util/src/mes/macro-decompose/index';
import Vue from 'vue';
import cellTypeMap from '@redviking/argonaut-core-ui/src/applet/design/cells/cellTypeMap';
import { getMacroTargetVarpId } from './macros/get-target-id';
import varpTypeMap from '@redviking/argonaut-core-ui/src/applet/design/varps/varpTypeMap';
import { viewMacro } from './validations/design.validations';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import type { ScreenDesigner } from '@redviking/argonaut-util/types/mes/screen-designer/screen-design';
import { getUID } from '@redviking/argonaut-util/src/getUID';
import type { FlowCfg } from '@redviking/argonaut-util/types/mes/applet-designs/flow.latest';
import { type AppletDesignVersionEntity } from './applet.entity';
import { generateDefaultGridLayout } from '@redviking/argonaut-core-ui/src/applet/design/screens/screen-preview/screenEditingUtil';
import {
    type GetAppletEventsResult,
    getAppletEvents,
} from '@redviking/argonaut-util/src/mes/get-applet-events';

export type AllArgoCellUsesInAllScreenLayouts = {
    [key in keyof Latest.Screen.Designer.GridLayoutAspectRatios]: string[];
};

type AvailableVarsOptions = {
    /**
     * limit the variables returned to those available to the specified provider by its ID or index
     */
    forProvider?: number | string | null;
    /**
     * if true, follow the "forProvider" logic, but also include the variables defined by the given provider
     */
    includeForProvider?: boolean | null;
    /**
     * limit the variables returned to those provided by varps satifying this predicate
     */
    varPredicate?: (provider: Latest.VarProviders.VarProvider, varIdx: number) => boolean;
};

type FindCellInCfgResp<T extends Latest.Screen.Cells.AppletCell> = {
    cellCfg: T,
    cellIdx: number,
    screenId: string,
    screenIdx: number,
    screen: Latest.Screen.Config,
}

type ScriptOrigin = {
    type: 'cell',
    screenId: string,
    cell: Latest.Screen.Cells.AppletCell,
} | {
    type: 'varp',
    varp: Latest.VarProviders.VarProvider,
};

type HoveredData = {
    cellId: string | null;
    rowOrColumn: {
        idx: number;
        type: keyof Latest.Screen.Designer.LinkedGridCellCoordinate;
    } | null;
}

type DragData = {
    gridId: string;
    rowOrColNum: number;
    pos: { x: number, y: number };
    direction: 'left' | 'right' | 'up' | 'down';
} | null;

type FlowModifiers = {
    id: string;
    arrange: () => void;
    groupSelected: () => void;
    removeSelectedItems: () => void;
    /** key: item id, value: label to display */
    selectedItems: Record<string, {
        title: string;
        subtitle?: string;
    }>;
};

export type AppletDesignState = {
    dragData: DragData;
    activeScreenId: Latest.Screen.Config['id'] | null;
    /**
     * NOTE: this value persists even when not looking at the Vars tab, in order to allow
     * the user to easily go back to the Varp they're working on by clicking the Variables tab.
     */
    activeVarProviderId: string | null;
    activeScreenLayout: Latest.Screen.Designer.GridLayoutAspectRatio,
    /** key: screenId, value: cellId */
    activeCellIdPerScreen: Record<string, string | null>;
    targetedGridId: string;
    validatingScripts: boolean;
    /** key: tab base id, value: tab instance id
     * TODO: This needs to have another layer for screen id
    */
    activeTabPerTabGroup: Record<string, string>;
    currentCellSelection: ScreenDesigner.CellSelection;
    screenDesignerViewType: ScreenDesigner.ToolBtn['type'];
    hoveredData: HoveredData;
    activeScriptId: string | null;
    activeFlowId: string | null;
    /** Object filled with methods to control different ways to interact with the current flow without having to pass them in as props to various components */
    flowModifiers: FlowModifiers | null;
};

export const appletDesignState = (): AppletDesignState => ({
    dragData: null,
    activeScreenId: null,
    activeVarProviderId: null,
    activeScreenLayout: 'desktop',
    screenDesignerViewType: 'viewCells',
    activeCellIdPerScreen: {},
    activeTabPerTabGroup: {},
    validatingScripts: false,
    targetedGridId: '',
    hoveredData: {
        cellId: null,
        rowOrColumn: null,
    },
    currentCellSelection: {
        rootCellId: null,
        includedCellIds: [],
    },
    activeScriptId: null,
    activeFlowId: null,
    flowModifiers: null,
});

export const mutations = mutationTree(appletDesignState, {
    /**
     * I don't know how to validate the monaco text model without setting the content to the scripts to validate on save.
     * This works fine, but if you're viewing any script tab, you'll see all the scripts fly by in the editor.
     * To workaround this, we show a "validating script" message under the editor so it's at least clear to the user what's happening... and looks intentional.
     */
    setValidatingScripts (state, payload: boolean) {
        state.validatingScripts = payload;
    },
    setActiveCell (state, payload: {
        screenId: string,
        /** if null, the screen will not have an active cell*/
        cellId: string | null,
    }) {
        if (payload.cellId) {
            // patch in
            state.activeCellIdPerScreen = {
                ...state.activeCellIdPerScreen,
                [payload.screenId]: payload.cellId,
            };
        } else {
            Vue.delete(state.activeCellIdPerScreen, payload.screenId);
        }
    },
    setScreenDesignerViewType (state, payload: ScreenDesigner.ToolBtn['type']) {
        state.screenDesignerViewType = payload;
    },
    setDragData (state, payload: DragData) {
        state.dragData = payload;
    },
    setActiveScreenLayout (state, payload: Latest.Screen.Designer.GridLayoutAspectRatio) {
        state.activeScreenLayout = payload;
    },
    setTargetGrid (state, payload: string) {
        state.targetedGridId = payload;
    },
    setHoveredData (state, payload: HoveredData) {
        state.hoveredData = payload;
    },
    setSelection (state, payload: ScreenDesigner.CellSelection) {
        state.currentCellSelection = payload;
    },
    setActiveTabInstanceForTabViewingRefMap (state, payload: Record<string, string>) {
        state.activeTabPerTabGroup = payload;
    },
    reset (state) {
        const newState: AppletDesignState = {
            dragData: null,
            activeCellIdPerScreen: {},
            activeScreenId: null,
            activeVarProviderId: null,
            activeScreenLayout: 'desktop',
            screenDesignerViewType: 'viewCells',
            activeTabPerTabGroup: {},
            validatingScripts: false,
            targetedGridId: '',
            hoveredData: {
                cellId: null,
                rowOrColumn: null,
            },
            currentCellSelection: {
                rootCellId: null,
                includedCellIds: [],
            },
            activeScriptId: null,
            activeFlowId: null,
            flowModifiers: null,
        };
        Object.assign(state, newState);
    },
    patchAppletDesignState (state, partialAppletDesignState: Partial<AppletDesignState>) {
        Object.assign(state, partialAppletDesignState);
    },
    setFlowModifiers (state, payload: FlowModifiers | null) {
        state.flowModifiers = payload;
    },
});

const scriptGetters = {
    getScriptCfgById: () => {
        return (scriptId: string, useOriginalCfg?: boolean): Latest.RuntimeScriptCfg | null => {
            const scriptCfg = useOriginalCfg
                ? accessor.originalEntityAsType('appletDesignVersion')?.config.scripts.find(s => s.id === scriptId)
                : accessor.appletDesign.idMap.scripts[scriptId]?.script;
            if (!scriptCfg) {
                return null;
            }
            return scriptCfg;
        };
    },
    findScriptOrigin: () => {
        const findScriptOrigin = (scriptId: string, useOriginalCfg?: boolean): ScriptOrigin | undefined => {
            const design = useOriginalCfg
                ? accessor.originalEntityAsType('appletDesignVersion')?.config
                : accessor.entityAsType('appletDesignVersion')?.config;
            // TODO: Prob should move the script id to the base varp type once other varp types support scripts so this loop doesnt have to check specific properties for a script id
            const scriptVarp = design?.varProviders.find(v => v.type === 'script' && v.attrs.scriptId === scriptId) as Latest.VarProviders.Script.Provider;
            if (scriptVarp) {
                return {
                    varp: scriptVarp,
                    type: 'varp',
                };
            }

            const scriptCell = accessor.appletDesign.findCellInCfg<Latest.Screen.Cells.Btn.Cell>((c): c is Latest.Screen.Cells.Btn.Cell => c.type === 'btn' && (c as Latest.Screen.Cells.Btn.Cell).attrs.linkedScriptId === scriptId, useOriginalCfg);

            if (scriptCell) {
                return {
                    type: 'cell',
                    cell: scriptCell.cellCfg,
                    screenId: scriptCell.screenId,
                };
            }
        };
        return findScriptOrigin;
    },
    activeScript (state: AppletDesignState): Latest.RuntimeScriptCfg | null {
        if (state.activeScriptId) {
            return accessor.appletDesign.idMap.scripts[state.activeScriptId]?.script || null;
        }
        return null;
    },
};

const cellGetters = {
    /**
     * gets the active cell for the active screen (if exists)
     */
    activeCell (state: AppletDesignState): Latest.Screen.Cells.AppletCell | null {
        const activeScreenId = state.activeScreenId;
        if (activeScreenId && state.activeCellIdPerScreen[activeScreenId]) {
            return accessor.appletDesign.idMap.screens[activeScreenId]?.screen.cells.find(c => c.id === state.activeCellIdPerScreen[activeScreenId]) || null;
        }
        return null;
    },
    findCellInCfg: () => {
        function findCellInCfg<CELL extends Latest.Screen.Cells.AppletCell> (predicate: (cell: CELL) => boolean, useOriginalCfg?: boolean): FindCellInCfgResp<CELL> | null;
        function findCellInCfg (predicate: (cell: Latest.Screen.Cells.AppletCell) => boolean, useOriginalCfg?: boolean): FindCellInCfgResp<Latest.Screen.Cells.AppletCell> | null {
            let ret: FindCellInCfgResp<Latest.Screen.Cells.AppletCell> | null = null;
            const screens = useOriginalCfg
                ? accessor.originalEntityAsType('appletDesignVersion')?.config?.screens || []
                : accessor.entityAsType('appletDesignVersion')?.config?.screens || [];
            screens.forEach((screen, screenIdx) => {
                screen.cells.forEach((cell, cellIdx) => {
                    if (predicate(cell)) {
                        ret = {
                            screen,
                            cellIdx,
                            screenIdx,
                            cellCfg: cell,
                            screenId: screen.id,
                        };
                    }
                });
            });
            return ret;
        }
        return findCellInCfg;
    },
    findCellsInCfg: () => {
        function findCellsInCfg<CELL extends Latest.Screen.Cells.AppletCell> (predicate: (cell: CELL) => boolean): {
            screenId: string,
            screenIdx: number,
            cellCfgs: CELL[],
        }[];
        function findCellsInCfg (predicate: (cell: Latest.Screen.Cells.AppletCell) => boolean): {
            screenId: string,
            screenIdx: number,
            cellCfgs: Latest.Screen.Cells.AppletCell[],
        }[] {
            const screens = accessor.entityAsType('appletDesignVersion')?.config?.screens || [];

            return screens.map((screen, screenIdx) => {
                return {
                    screenId: screen.id,
                    screenIdx,
                    cellCfgs: screen.cells.filter(predicate),
                };
            });
        }
        return findCellsInCfg;
    },
    getAllChildCells: () => {
        function getAllChildCells<CELL extends Latest.Screen.Cells.AppletCell> (cellId: string, useOriginalCfg?: boolean): {
            cellIdx: number;
            cellCfg: CELL;
        }[];
        function getAllChildCells (cellId: string, useOriginalCfg?: boolean): {
            cellIdx: number;
            cellCfg: Latest.Screen.Cells.AppletCell;
        }[] {
            const ret: {
                cellIdx: number;
                cellCfg: Latest.Screen.Cells.AppletCell;
            }[] = [];
            const screen = useOriginalCfg
                ? accessor.originalEntityAsType('appletDesignVersion')?.config?.screens.find(s => s.cells.find(c => c.id === cellId))
                : accessor.entityAsType('appletDesignVersion')?.config?.screens.find(s => s.cells.find(c => c.id === cellId));
            if (screen) {
                screen.cells.forEach((cell, cellIdx) => {
                    if (cell.attrs.parentCellId === cellId) {
                        ret.push({ cellIdx, cellCfg: cell });
                    }
                });
            }
            return ret;
        }
        return getAllChildCells;
    },
    getParentCell: () => {
        function getParentCell<CELL extends Latest.Screen.Cells.AppletCell> (cell: Latest.Screen.Cells.AppletCell): CELL | null;
        function getParentCell (cell: Latest.Screen.Cells.AppletCell): Latest.Screen.Cells.AppletCell | null {
            const screen = Object.values(accessor.appletDesign.idMap.screens).find(screenData => screenData && screenData.screen.cells.find(c => c.id === cell.id))?.screen;
            if (screen) {
                return screen.cells.find(c => c.id === cell.attrs.parentCellId) || null;
            }
            return null;
        }
        return getParentCell;
    },
};

export const getters = getterTree(appletDesignState, {
    /**
     * convenience util for getting the indexes and data for each of the important things in the config.
     *
     * Given only the ID of a thing, you can get the config object for it and where it is in the whole config.
     */
    idMap: (): {
        screens: Record<string, { idx: number, screen: Latest.Screen.Config } | undefined>;
        macros: Record<string, { idx: number, macro: Latest.Macros.AppletMacro } | undefined>;
        scripts: Record<string, { idx: number, script: Latest.RuntimeScriptCfg } | undefined>;
        varps: Record<string, { idx: number, varp: Latest.VarProviders.VarProvider } | undefined>;
    } => {
        const entityData = accessor.entityAsType('appletDesignVersion');
        return {
            screens: (entityData?.config.screens || []).map((screen, idx) => ({ screen, idx })).reduce<Record<string, { idx: number, screen: Latest.Screen.Config }>>((prev, curr) => {
                prev[curr.screen.id] = curr;
                return prev;
            }, {}),
            varps: (entityData?.config.varProviders || []).map((varp, idx) => ({ varp, idx })).reduce<Record<string, { idx: number, varp: Latest.VarProviders.VarProvider }>>((prev, curr) => {
                prev[curr.varp.id] = curr;
                return prev;
            }, {}),
            macros: (entityData?.config.macros || []).map((macro, idx) => ({ macro, idx })).reduce<Record<string, { idx: number, macro: Latest.Macros.AppletMacro }>>((prev, curr) => {
                prev[curr.macro.id] = curr;
                return prev;
            }, {}),
            scripts: (entityData?.config.scripts || []).map((script, idx) => ({ script, idx })).reduce<Record<string, { idx: number, script: Latest.RuntimeScriptCfg }>>((prev, curr) => {
                prev[curr.script.id] = curr;
                return prev;
            }, {}),
        };
    },
    activeScreenCellMap: (state): Record<string, { idx: number, cell: Latest.Screen.Designer.LinkedGridCell } | undefined> => {
        const entityData = accessor.entityAsType('appletDesignVersion');
        const screen = entityData?.config.screens.find(s => s.id === state.activeScreenId);
        return screen ? Object.fromEntries(screen.gridLayoutAspectRatios[state.activeScreenLayout].linkedGridCells.map((cell, idx) => [ cell.id, { idx, cell } ])) : {};
    },

    ...cellGetters,
    ...scriptGetters,

    /**
     * getter fn that can determine the variables available, optionally with some limiting conditions.
     *
     * `includeForProvider` will have no affect unless `forProvider` is set.
     */
    availableVars: (_) => {
        return function availableVars (options?: AvailableVarsOptions): string[] {
            const varPredicate = options?.varPredicate || (() => true);
            const vars: string[] = [];
            let forProviderStopFlag = false;
            const varps = accessor.entityAsType('appletDesignVersion')?.config?.varProviders || [];
            // for each provider
            for (let vpIdx = 0; vpIdx < varps.length; vpIdx++) {
                const vp = varps[vpIdx];

                // stop if hitting the "forProvider"
                if (
                    (typeof options?.forProvider === 'number' && vpIdx === options?.forProvider) ||
                    (typeof options?.forProvider === 'string' && vp.id === options?.forProvider)
                ) {
                    if (options?.includeForProvider) {
                        forProviderStopFlag = true;
                    } else {
                        break;
                    }
                }

                // for each of the provider's output variables
                for (let outputVarIdx = 0; outputVarIdx < vp.outputs.length; outputVarIdx++) {
                    const outputVarName = vp.outputs[outputVarIdx];
                    if (outputVarName && varPredicate(vp, outputVarIdx)) {
                        vars.push(outputVarName);
                    }
                }
                if (forProviderStopFlag) {
                    break;
                }
            }
            return vars;
        };
    },
    getVarOrigin: () => (varName: string) => {
        const entityData = accessor.entityAsType('appletDesignVersion');
        const originVarp = entityData?.config?.varProviders.find(vp => {
            return vp.outputs.includes(varName);
        });
        if (!originVarp) {
            throw new Error(`Could not find origin for Variable Provider for ${varName}`);
        }
        if (originVarp.type === 'macroTarget') {
            const macro = entityData?.config.macros.find(m => m.id === originVarp.attrs.macroId);
            if (macro === undefined) {
                throw new Error(`Could not find macro for ${varName}`);
            }
            const { patchState, routeName } = viewMacro(macro.id, entityData!);
            accessor.appletDesign.patchAppletDesignState(patchState);
            router.replace({ name: routeName });
        }
        return originVarp;
    },

    // this is common to filter on, so we compute it in a single place here
    localVariables: (): string[] => {
        return accessor.appletDesign.availableVars({
            varPredicate: vp => vp.type === 'local',
        });
    },

    localVarps: (): Latest.VarProviders.Local.Provider[] => {
        return (accessor.entityAsType('appletDesignVersion')?.config?.varProviders.filter(vp => vp.type === 'local') || []) as Latest.VarProviders.Local.Provider[];
    },

    activeVarProvider (state): Latest.VarProviders.VarProvider | null {
        if (state.activeVarProviderId) {
            return accessor.appletDesign.idMap.varps[state.activeVarProviderId]?.varp || null;
        }
        return null;
    },

    appletEvents (): GetAppletEventsResult {
        const entityData = accessor.entityAsType('appletDesignVersion');
        if (entityData) {
            return getAppletEvents(entityData.config);
        } else {
            return {};
        }
    },
});

const cellActions = actionTree({
    state: appletDesignState,
    mutations,
    getters,
}, {
    addCell (ctx, payload: {
        screenId: string,
        cellCfg: Latest.Screen.Cells.AppletCell,
        /** @default true */
        activate?: boolean,
    }) {
        const screenCfg = accessor.appletDesign.idMap.screens[payload.screenId]?.screen;
        if (!screenCfg) {
            throw new Error('Screen not found');
        }

        accessor.appletDesign.setScreenCfg({
            ...screenCfg,
            cells: replacerListUtils.insertItem(screenCfg.cells, payload.cellCfg),
        });

        // should we activate this new cell?
        if (payload.activate ?? true) {
            accessor.appletDesign.setActiveCell({
                screenId: payload.screenId,
                cellId: payload.cellCfg.id,
            });
        }
    },
    deleteCell (ctx, payload: {
        screenId: string,
        cellCfg: Latest.Screen.Cells.AppletCell,
    }) {
        // cells might have custom delete process
        const typedDeleteCell = cellTypeMap[payload.cellCfg.type as keyof typeof cellTypeMap]?.delete;
        if (typedDeleteCell) {
            typedDeleteCell(payload.cellCfg);
        }

        // are there any cells that reference this cell as a parent?
        const childCells = accessor.appletDesign.getAllChildCells(payload.cellCfg.id);
        if (childCells && childCells.length > 0) {
            childCells.forEach(cellData => {
                accessor.appletDesign.deleteCell({
                    cellCfg: cellData.cellCfg,
                    screenId: payload.screenId,
                });
            });
        }

        // ensure cell is deactivated
        const activeCellForScreen = accessor.appletDesign.activeCellIdPerScreen[payload.screenId];
        if (activeCellForScreen) {
            accessor.appletDesign.setActiveCell({ screenId: payload.screenId, cellId: null });
        }

        // remove the cell from any grid layout cells
        const screenCfg = accessor.appletDesign.idMap.screens[payload.screenId]!.screen;
        const gridLayoutAspectRatio = screenCfg.gridLayoutAspectRatios;


        Object.entries(gridLayoutAspectRatio).forEach(([ aspectRatio, gridLayout ]) => {
            gridLayoutAspectRatio[aspectRatio as keyof typeof gridLayoutAspectRatio] = {
                ...gridLayout,
                linkedGridCells: gridLayout.linkedGridCells.map(lgc => {
                    if (lgc.type === 'regular' && lgc.argoCellId === payload.cellCfg.id) {
                        const newLgc: Latest.Screen.Designer.RegularGridCell = {
                            ...lgc,
                            argoCellId: '',
                        };
                        return newLgc;
                    }
                    return lgc;
                }),
            };
        });
        screenCfg.gridLayoutAspectRatios = gridLayoutAspectRatio;
        const cellIdx = screenCfg.cells.findIndex(c => c.id === payload.cellCfg.id);
        if (cellIdx !== -1) {
            accessor.appletDesign.setScreenCfg({
                ...screenCfg,
                cells: replacerListUtils.deleteItem(screenCfg.cells, cellIdx),
            });
        }
    },
    updateCell (ctx, payload: { screenId: string, cellCfg: Latest.Screen.Cells.AppletCell }) {
        const screenCfg = accessor.appletDesign.idMap.screens[payload.screenId]!.screen;
        accessor.appletDesign.setScreenCfg({
            ...screenCfg,
            cells: replacerListUtils.updateItem(screenCfg.cells, payload.cellCfg, screenCfg.cells.findIndex(c => c.id === payload.cellCfg.id)),
        });
    },
});

const screenActions = actionTree({
    state: appletDesignState,
    mutations,
    getters,
}, {
    setScreens (ctx, screens: Latest.Screen.Config[]) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const newEntityData: typeof entityData = { ...entityData };
        newEntityData.config.screens = screens;
        accessor.setPageEntity({ type: 'appletDesignVersion', entity: newEntityData });
    },
    setScreenCfg (ctx, screenCfg: Latest.Screen.Config) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const screenIdx = accessor.appletDesign.idMap.screens[screenCfg.id]!.idx;
        const newScreens = replacerListUtils.updateItem(entityData.config.screens, screenCfg, screenIdx);
        accessor.appletDesign.setScreens(newScreens);
    },
    setActiveScreen ({ commit }, payload: { screenId: string | null }) {
        const patchPayload: Partial<AppletDesignState> = {
            activeScreenId: payload.screenId,
        };
        commit('patchAppletDesignState', patchPayload);

        // if we're not at the screen route, go there
        if (payload.screenId && router.currentRoute.name !== screensTabRoute) {
            router.replace({ name: screensTabRoute, query: router.currentRoute.query });
        }
        accessor.appletDesign.setTargetGrid('');
        accessor.appletDesign.setActiveScreenLayout('desktop');
        accessor.appletDesign.setScreenDesignerViewType('viewCells');
        accessor.appletDesign.setActiveTabInstanceForTabViewingRefMap({});
        accessor.appletDesign.setSelection({
            rootCellId: null,
            includedCellIds: [],
        });
        accessor.appletDesign.setHoveredData({
            cellId: null,
            rowOrColumn: null,
        });
    },
    addNewScreen () {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const newScreen: Latest.Screen.Config = {
            cells: [],
            id: uuidv4(),
            name: 'New Screen',
            gridLayoutAspectRatios: {
                mobile: generateDefaultGridLayout(false),
                tablet: generateDefaultGridLayout(false),
                desktop: generateDefaultGridLayout(true),
                largeDisplay: generateDefaultGridLayout(false),
            },
            operatorAuth: { required: Boolean(entityData.config.operatorAuth) },
        };

        accessor.appletDesign.setScreens([
            ...entityData.config.screens,
            newScreen,
        ]);
        accessor.appletDesign.setActiveScreen({ screenId: newScreen.id });
    },
    removeScreen (ctx, screen: Latest.Screen.Config) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        accessor.appletDesign.setActiveCell({ screenId: screen.id, cellId: null });

        if (accessor.appletDesign.activeScreenId === screen.id) {
            accessor.appletDesign.setActiveScreen({ screenId: null });
        }

        screen.cells.forEach(cell => {
            accessor.appletDesign.deleteCell({
                cellCfg: cell,
                screenId: screen.id,
            });
        });

        const tabGroupRootIds: string[] = [];
        Object.values(screen.gridLayoutAspectRatios).forEach(gridLayout => {
            gridLayout.linkedGridCells.forEach(lgc => {
                if (lgc.type === 'tabGroup') {
                    tabGroupRootIds.push(lgc.id);
                }
            });
        });
        const copyTabGroupViewingRefMap = { ...accessor.appletDesign.activeTabPerTabGroup };
        tabGroupRootIds.forEach(tabGroupRootId => {
            delete copyTabGroupViewingRefMap[tabGroupRootId];
        });
        accessor.appletDesign.setActiveTabInstanceForTabViewingRefMap(copyTabGroupViewingRefMap);

        accessor.appletDesign.setScreens(entityData.config.screens.filter(s => s.id !== screen.id));

        accessor.appletDesign.setTargetGrid('');
        accessor.appletDesign.setActiveScreenLayout('desktop');
        accessor.appletDesign.setScreenDesignerViewType('viewCells');
        accessor.appletDesign.setActiveTabInstanceForTabViewingRefMap({});
        accessor.appletDesign.setSelection({
            rootCellId: null,
            includedCellIds: [],
        });
        accessor.appletDesign.setHoveredData({
            cellId: null,
            rowOrColumn: null,
        });
    },
});

const varpActions = actionTree({
    state: appletDesignState,
    mutations,
    getters,
}, {
    setActiveVarProviderId ({ commit }, varpId: string | null) {
        if (varpId) {
            const varp = accessor.appletDesign.idMap.varps[varpId]!.varp;
            if (varp && varp.type !== 'macroTarget') {
                commit('patchAppletDesignState', {
                    activeVarProviderId: varpId,
                });
                if (router.currentRoute.name !== varsTabRoute) {
                    router.replace({ name: varsTabRoute, query: router.currentRoute.query });
                }
            }
        } else {
            commit('patchAppletDesignState', { activeVarProviderId: null });
        }
    },
    setVarps (ctx, varProviders: Latest.VarProviders.VarProvider[]) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const newEntityData: typeof entityData = { ...entityData };
        newEntityData.config.varProviders = varProviders as Latest.VarProviders.VarProvider[];
        accessor.setPageEntity({ type: 'appletDesignVersion', entity: newEntityData });
    },
    addVarp (ctx, payload: {
        varpCfg: Latest.VarProviders.VarProvider,
        /** @default true */
        activate?: boolean;
    }) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        accessor.appletDesign.setVarps([
            ...entityData.config.varProviders,
            payload.varpCfg,
        ]);

        if (payload.activate ?? true) {
            accessor.appletDesign.setActiveVarProviderId(payload.varpCfg.id);
        }
    },
    removeVarProv (ctx, varpId: string) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        if (accessor.appletDesign.activeVarProviderId) {
            accessor.appletDesign.setActiveVarProviderId(null);
        }
        const varpCfg = accessor.appletDesign.idMap.varps[varpId]!;
        const typedDeleteVarp = varpTypeMap[varpCfg.varp.type]?.delete;
        if (typedDeleteVarp) {
            typedDeleteVarp(varpCfg.varp);
        }

        entityData.config.sparkplugDeviceMetrics.forEach(spdm => {
            if (varpCfg.varp.outputs.includes(spdm.name)) {
                accessor.appletDesign.removeSparkplugMetric({
                    spMetricName: spdm.name,
                });
            }
        });

        accessor.appletDesign.setVarps(replacerListUtils.deleteItem(entityData.config.varProviders, varpCfg.idx));
    },
    setVarProvider (ctx, varp: Latest.VarProviders.VarProvider) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const varpIdx = accessor.appletDesign.idMap.varps[varp.id];
        if (varpIdx) {
            accessor.appletDesign.setVarps(replacerListUtils.updateItem(entityData.config.varProviders as Latest.VarProviders.VarProvider[], {
                ...varp,

                // use the unique set of inputs and outputs, and sort them for unsaved change detection
                inputs: [ ...new Set([ ...(varp.inputs || []) ]) ].sort(),
                outputs: [ ...varp.outputs || [] ].sort(),
            }, varpIdx.idx));
        }
    },
});

const macroActions = actionTree({
    state: appletDesignState,
    mutations,
    getters,
}, {
    addMacro (ctx, payload: {
        screenId: string,
        macroCfg: Latest.Macros.AppletMacro,
    }) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;

        // insert macro
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...entityData,
                config: {
                    ...entityData.config,
                    macros: [
                        ...entityData.config.macros || [],
                        payload.macroCfg,
                    ],
                },
            },
        });

        // run the macro fn with the default cfg to find out what the vars are for the macro as a whole (for its varp target)
        const newVarps = decomposeMacro(payload.macroCfg) || [];
        const lastVarp = (newVarps || []).pop();
        const outputVars = lastVarp?.outputs || [];
        /** unique list of all input vars */
        const inputVars = [ ...new Set(newVarps.flatMap(varp => varp.inputs || [])) ];

        // create and insert the macro varp target
        const macroVarpTarget: Latest.VarProviders.MacroVarpTarget = {
            type: 'macroTarget',
            attrs: {
                macroType: payload.macroCfg.type,
                macroId: payload.macroCfg.id,
            },
            id: getMacroTargetVarpId(payload.macroCfg.id),
            outputs: outputVars.sort(),
            inputs: inputVars.sort(),
        };
        accessor.appletDesign.addVarp({ varpCfg: macroVarpTarget, activate: false });
    },
    /**
     * updates a macro config, and optionally the inputs and outputs for its varp target
     */
    setMacroCfg (ctx, payload: { macroCfg: Latest.Macros.AppletMacro, inputs?: string[], outputs?: string[] }) {
        let entityData = accessor.entityAsType('appletDesignVersion')!;
        const { idx: macroIdx } = accessor.appletDesign.idMap.macros[payload.macroCfg.id]!;
        const varpTarget = entityData.config.varProviders.find(varp => varp.type === 'macroTarget' && varp.attrs.macroId === payload.macroCfg.id)!;

        if (payload.inputs || payload.outputs) {
            accessor.appletDesign.setVarProvider({
                ...varpTarget,
                inputs: payload.inputs || [],
                outputs: payload.outputs || [],
            });
        }

        // ensure the value here is up to date
        entityData = accessor.entityAsType('appletDesignVersion')!;

        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...entityData,
                config: {
                    ...entityData.config,
                    macros: replacerListUtils.updateItem(entityData.config.macros!, payload.macroCfg, macroIdx),
                },
            },
        });
    },
    removeMacroCfg (ctx, payload: {
        macroId: string;
    }) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        accessor.setPageEntity({
            type: 'appletDesignVersion',
            entity: {
                ...entityData,
                config: {
                    ...entityData.config,
                    macros: entityData.config.macros.filter(m => m.id !== payload.macroId),
                    varProviders: entityData.config.varProviders.filter(v => v.type !== 'macroTarget' || v.attrs.macroId !== payload.macroId),
                },
            },
        });
    },
});

const scriptActions = actionTree({
    getters,
    mutations,
    state: appletDesignState,
}, {
    setActiveScriptId (ctx, scriptId: string | null) {
        ctx.commit('patchAppletDesignState', { activeScriptId: scriptId });
        if (scriptId && router.currentRoute.name !== scriptsTabRoute) {
            router.replace({ name: scriptsTabRoute, query: router.currentRoute.query });
        }
    },
    addScript (ctx, scriptCfg: Latest.RuntimeScriptCfg) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const newEntityData: AppletDesignVersionEntity = {
            ...entityData,
            config: {
                ...entityData.config,
                scripts: [
                    ...entityData.config.scripts,
                    scriptCfg,
                ],
            },
        };
        accessor.setPageEntity({ entity: newEntityData, type: 'appletDesignVersion' });
    },
    removeScript (ctx, payload: {
        scriptId: string;
    }) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const scriptCfg = accessor.appletDesign.idMap.scripts[payload.scriptId]?.script;
        if (!scriptCfg) {
            throw new Error('Could not find script value to remove');
        }
        const newEntityData: AppletDesignVersionEntity = {
            ...entityData,
            config: {
                ...entityData.config,
                scripts: entityData.config.scripts.filter(s => s.id !== payload.scriptId),
            },
        };
        accessor.setPageEntity({ entity: newEntityData, type: 'appletDesignVersion' });
    },
    setScript (ctx, scriptCfg: Latest.RuntimeScriptCfg) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const scriptIdx = accessor.appletDesign.idMap.scripts[scriptCfg.id]?.idx;
        if (scriptIdx !== undefined && scriptIdx !== -1) {
            const newEntityData: AppletDesignVersionEntity = {
                ...entityData,
                config: {
                    ...entityData.config,
                    scripts: [
                        ...entityData.config.scripts.slice(0, scriptIdx),
                        scriptCfg,
                        ...entityData.config.scripts.slice(scriptIdx + 1),
                    ],
                },
            };
            accessor.setPageEntity({ entity: newEntityData, type: 'appletDesignVersion' });
        }
    },
});

const sparkplugMetricActions = actionTree({
    getters,
    mutations,
    state: appletDesignState,
}, {
    addSparkplugMetric (ctx, spMetric: Latest.SparkplugDeviceMetric) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const newEntityData: AppletDesignVersionEntity = {
            ...entityData,
            config: {
                ...entityData.config,
                sparkplugDeviceMetrics: [
                    ...entityData.config.sparkplugDeviceMetrics,
                    spMetric,
                ],
            },
        };
        accessor.setPageEntity({ entity: newEntityData, type: 'appletDesignVersion' });
    },
    removeSparkplugMetric (ctx, payload: {
        spMetricName: string;
    }) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const newEntityData: AppletDesignVersionEntity = {
            ...entityData,
            config: {
                ...entityData.config,
                sparkplugDeviceMetrics: entityData.config.sparkplugDeviceMetrics.filter(sp => sp.name !== payload.spMetricName),
            },
        };
        accessor.setPageEntity({ entity: newEntityData, type: 'appletDesignVersion' });
    },
    setSparkplugMetric (ctx, spMetric: Latest.SparkplugDeviceMetric) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const existingSpMetricIdx = entityData.config.sparkplugDeviceMetrics.findIndex(sp => sp.name === spMetric.name);
        if (existingSpMetricIdx !== undefined && existingSpMetricIdx !== -1) {
            const newEntityData: AppletDesignVersionEntity = {
                ...entityData,
                config: {
                    ...entityData.config,
                    sparkplugDeviceMetrics: [
                        ...entityData.config.sparkplugDeviceMetrics.slice(0, existingSpMetricIdx),
                        spMetric,
                        ...entityData.config.sparkplugDeviceMetrics.slice(existingSpMetricIdx + 1),
                    ],
                },
            };
            accessor.setPageEntity({ entity: newEntityData, type: 'appletDesignVersion' });
        }
    },
});

const actions = actionTree({
    state: appletDesignState,
    mutations,
    getters,
}, {
    ...cellActions,
    ...screenActions,
    ...varpActions,
    ...macroActions,
    ...scriptActions,
    ...sparkplugMetricActions,
    setActiveFlowId ({ commit }, flowId: string | null) {
        commit('patchAppletDesignState', { activeFlowId: flowId });
        if (flowId && router.currentRoute.name !== flowsTabRoute) {
            router.replace({ name: flowsTabRoute, query: router.currentRoute.query });
        }
    },
    addFlow () {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const id = getUID();
        const newEntityData = { ...entityData };
        newEntityData.config.flows[id] = {
            id,
            name: 'Unnamed Flow',
            nodes: {},
            connections: {},
            comments: {},
            groups: {},
        };
        accessor.setPageEntity({ type: 'appletDesignVersion', entity: newEntityData });
        accessor.appletDesign.setActiveFlowId(id);
    },
    deleteFlow (_, payload: { flowId: string }) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const newEntityData = { ...entityData };
        delete newEntityData.config.flows[payload.flowId];
        accessor.setPageEntity({ type: 'appletDesignVersion', entity: newEntityData });
    },
    updateFlowCfg (_, payload: FlowCfg) {
        const entityData = accessor.entityAsType('appletDesignVersion')!;
        const newEntityData = { ...entityData };
        newEntityData.config.flows[payload.id] = payload;
        accessor.setPageEntity({ type: 'appletDesignVersion', entity: newEntityData });
    },
});

export const appConfigStore = {
    namespaced: true,
    state: appletDesignState,
    getters,
    mutations,
    actions,
};

const storeType = getStoreType(appConfigStore);
export type AppConfigStore = typeof storeType;

export default appConfigStore;
