import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

import z from 'zod';

export const variableBehaviorTypeSchema = z.union([
    z.literal('constant'),
    z.literal('timestamp'),
    z.literal('script'),
]);

const baseVarBehaviorSchema = z.object({
    type: variableBehaviorTypeSchema,
});

const constantVarBehaviorSchema = baseVarBehaviorSchema.extend({
    type: z.literal('constant'),
    value: z.union([ z.string(), z.number() ]).nullable(),
});

export const scriptVarBehaviorSchema = baseVarBehaviorSchema.extend({
    type: z.literal('script'),
});

const timestampVarBehaviorSchema = baseVarBehaviorSchema.extend({
    type: z.literal('timestamp'),
});
const navToScreenVarBehaviorSchema = baseVarBehaviorSchema.extend({
    type: z.literal('navToScreen'),
});
const nextScreenVarBehaviorSchema = baseVarBehaviorSchema.extend({
    type: z.literal('nextScreen'),
});
const prevScreenVarBehaviorSchema = baseVarBehaviorSchema.extend({
    type: z.literal('prevScreen'),
});

const varBehaviorSchema = z.discriminatedUnion('type', [
    scriptVarBehaviorSchema,
    timestampVarBehaviorSchema,
    constantVarBehaviorSchema,
    navToScreenVarBehaviorSchema,
    nextScreenVarBehaviorSchema,
    prevScreenVarBehaviorSchema,
]);

export const btnCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('btn'),
    attrs: cellCfgAttrsBaseSchema.extend({
        enabled: z.union([ z.string(), z.number() ]),
        label: z.string(),
        color: z.string(),
        variant: z.enum([ 'text', 'outlined' ]).nullable().optional(),
        targetVar: z.string().optional(),
        targetScreenId: z.string().optional(),
        varBehavior: varBehaviorSchema.optional(),
        linkedScriptId: z.string().optional(),
        size: z.enum([ 'small', 'medium', 'large', 'x-large' ]).default('medium'),
        emitAppletEvent: z.boolean().default(false),
        appletEventName: z.string().optional(),
    }),
    modifiers: z.record(z.enum([
        'enabled',
        'label',
        'color',
        'variant',
        'targetVar',
        'size',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
