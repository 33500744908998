import z from 'zod';
import { scriptProviderSchema } from './var-providers/script.zod';
import { sliceProviderSchema } from './var-providers/slice.zod';
import { timerProviderSchema } from './var-providers/timer.zod';
import { lockingProviderSchema } from './var-providers/locking.zod';
import { processProviderSchema } from './var-providers/process.zod';
import { localProviderConfigSchema } from './var-providers/local.zod';
import { calendarProviderSchema } from './var-providers/calendar.zod';
import { formattedProviderSchema } from './var-providers/formatted.zod';
import { sparkplugProviderSchema } from './var-providers/sparkplug.zod';
import { macroVarpTargetSchema } from './var-providers/macro-target.zod';
import { varStatusProviderSchema } from './var-providers/var-status.zod';
import { tcpClientProviderSchema } from './var-providers/tcp-client.zod';
import { tcpServerProviderSchema } from './var-providers/tcp-server.zod';
import { udpSocketProviderSchema } from './var-providers/udp-socket.zod';
import { materialIdProviderSchema } from './var-providers/material-id.zod';
import { comparativeProviderSchema } from './var-providers/comparative.zod';
import { createMaterialProviderSchema } from './var-providers/create-material.zod';
import { mqttPublishProviderConfigSchema } from './var-providers/mqtt-publish.zod';
import { mqttSubscribeProviderConfigSchema } from './var-providers/mqtt-subscribe.zod';
import { sparkplugDeviceWriteProviderSchema } from './var-providers/sparkplug-device-write.zod';
import { tabGroupControllerProviderConfigSchema } from './var-providers/tab-group-controller.zod';

export const varpSchema = z.discriminatedUnion('type', [
    timerProviderSchema,
    sliceProviderSchema,
    scriptProviderSchema,
    processProviderSchema,
    macroVarpTargetSchema,
    lockingProviderSchema,
    calendarProviderSchema,
    tcpClientProviderSchema,
    varStatusProviderSchema,
    tcpServerProviderSchema,
    sparkplugProviderSchema,
    formattedProviderSchema,
    udpSocketProviderSchema,
    materialIdProviderSchema,
    comparativeProviderSchema,
    localProviderConfigSchema,
    createMaterialProviderSchema,
    mqttPublishProviderConfigSchema,
    mqttSubscribeProviderConfigSchema,
    sparkplugDeviceWriteProviderSchema,
    tabGroupControllerProviderConfigSchema,
]);

export const varpTypes = {
    timer: timerProviderSchema,
    slice: sliceProviderSchema,
    script: scriptProviderSchema,
    process: processProviderSchema,
    locking: lockingProviderSchema,
    calendar: calendarProviderSchema,
    local: localProviderConfigSchema,
    tcpClient: tcpClientProviderSchema,
    varStatus: varStatusProviderSchema,
    tcpServer: tcpServerProviderSchema,
    sparkplug: sparkplugProviderSchema,
    formatted: formattedProviderSchema,
    udpSocket: udpSocketProviderSchema,
    materialId: materialIdProviderSchema,
    comparative: comparativeProviderSchema,
    macroVarpTarget: macroVarpTargetSchema,
    createMaterial: createMaterialProviderSchema,
    mqttPublish: mqttPublishProviderConfigSchema,
    mqttSubscribe: mqttSubscribeProviderConfigSchema,
    sparkplugDeviceWrite: sparkplugDeviceWriteProviderSchema,
    tabGroupController: tabGroupControllerProviderConfigSchema,
};
