import { z } from 'zod';
import { varNameSchema } from './var.zod';
import { localVarCfgSchema } from './local.zod';
import { varpConfigBase } from './var-provider.zod';

export const udfSourceSchema = z.object({
    /** must be unique across sources */
    matClassName: z.string(),
    matClassId: z.string(),
    udfColumnId: z.string(),
    udfName: z.string(),
});

export const materialIdProviderSchema = varpConfigBase.extend({
    type: z.literal('materialId'),
    inputs: z.array(varNameSchema),
    attrs: z.object({
        inputVar: z.string(),
        outputVar: z.string(),
        outputVarModelNumber: z.string().optional(),
        outputVarClassName: z.string().optional(),
        udfOutputs: z.array(z.object({
            /** first column in the config ui */
            outputVar: z.string(),
            /** second column in the config ui */
            default: localVarCfgSchema.omit({ name: true }),
            /** each corresponds to an additional column in the config ui */
            sources: z.array(udfSourceSchema),
        })),
    }),
});
