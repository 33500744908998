import { z } from 'zod';
import { DeployParamKind, deployParamSchema } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { varpConfigBase } from './var-provider.zod';
import { okVarShemaWithContent, varNameSchema, varSchema } from '../../v3/var-providers/var.zod';

const baseLocalVarValSchema = z.object({
    name: varNameSchema,
});

export const localVarCfgSchema = baseLocalVarValSchema.extend({
    type: z.literal('localVar'),
    varCfg: varSchema,
});

const localVarCfgDeployParamSchema = deployParamSchema(baseLocalVarValSchema.extend({
    type: z.literal('deployParam'),
    varCfg: okVarShemaWithContent,
    deployParamKind: z.literal(DeployParamKind.localVarValue),
}));


export const localProviderConfigSchema = varpConfigBase.extend({
    type: z.literal('local'),
    attrs: z.object({
        vars: z.array(z.union([
            localVarCfgDeployParamSchema,
            localVarCfgSchema,
        ])),
    }),
});

