import { z } from 'zod';
import { optionalVarNameSchema, strVarSchema, varNameSchema } from '../var-providers/var.zod';
import { varOrConstSchema, varpConfigBase } from './var-provider.zod';
import { varConditionSchema } from './conditional.zod';

export const udpSocketProviderSchema = varpConfigBase.extend({
    type: z.literal('udpSocket'),
    attrs: z.object({
        port: z.number().min(1),
        host: z.string().min(1),
        receivePayloadVar: varNameSchema,
        sentTsVar: optionalVarNameSchema,
        condition: varConditionSchema,
        receivedTsVar: optionalVarNameSchema,
        sendPayloadVar: varOrConstSchema(strVarSchema),
        encoding: z.union([ z.literal('utf8'), z.literal('hex') ]),
        bindPort: z.union([
            z.object({
                shouldBindPort: z.literal(false),
            }),
            z.object({
                shouldBindPort: z.literal(true),
                clientTimeout: z.number().min(1),
            }),
        ]),
    }),
});
