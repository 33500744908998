import { z } from 'zod';
import { SchemaHookKind, schemaWithHook } from '../../../../../src/schemaWithHook';

const okVarStatusSchema = z.object({
    status: z.literal('ok'),
});

export const strVarSchema = okVarStatusSchema.extend({
    val: z.string(),
    type: z.literal('str'),
});

// better way to do this?
export const strVarWithMinLengthSchema = (minLength: number) => strVarSchema.omit({
    val: true,
}).extend({
    val: z.string().min(minLength),
});

export const numVarSchema = okVarStatusSchema.extend({
    val: z.number(),
    type: z.literal('num'),
});

export const boolVarSchema = okVarStatusSchema.extend({
    val: z.boolean(),
    type: z.literal('bool'),
});

export const okVarShemaWithContent = z.discriminatedUnion('type', [
    strVarSchema,
    numVarSchema,
    boolVarSchema,
]);

export const nullVarSchema = okVarStatusSchema.extend({
    val: z.null(),
    type: z.literal('null'),
});

export const okVarSchema = z.discriminatedUnion('type', [
    ...okVarShemaWithContent.options,
    nullVarSchema,
]);

export const okVarValSchema = okVarSchema.options.map(o => o.shape.val);

export type OkAppletVar = z.infer<typeof okVarSchema>;

export const pendingVarSchema = z.object({
    val: z.null(),
    type: z.literal('pending'),
    status: z.literal('pending'),
});

export const errorVarSchema = z.object({
    val: z.instanceof(Error),
    type: z.literal('error'),
    status: z.literal('error'),
});

/**
 * TODO we might want to refactor the `status: Error` to a `status: 'error'` and `message: string`,
 * that way we can send the error over the wire easily without transformation logic.
 */
export const varSchema = z.discriminatedUnion('type', [
    errorVarSchema,
    pendingVarSchema,
    ...okVarSchema.options,
]).describe('an instance of a variable');

export const varNameSchema = schemaWithHook({
    schema: z.string().min(1),
    hook: SchemaHookKind.VarName,
});

export const optionalVarNameSchema = schemaWithHook({
    schema: z.string(),
    hook: SchemaHookKind.VarName,
});
