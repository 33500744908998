import { z } from 'zod';
import { nodeCfgSchema } from './nodes';

export const flowConnectionSchema = z.object({
    sourceNodeId: z.string(),
    sourceNodePort: z.string(),
    targetNodeId: z.string(),
    targetNodePort: z.string(),
});

export const flowCfgSchema = z.object({
    id: z.string(),
    name: z.string(),
    nodes: z.record(nodeCfgSchema),
    connections: z.record(flowConnectionSchema),
    comments: z.record(z.object({
        id: z.string(),
        label: z.string(),
        x: z.number(),
        y: z.number(),
        nodeLink: z.string().optional(),
    })),
    groups: z.record(z.object({
        id: z.string(),
        label: z.string(),
        nodeLinks: z.array(z.string()),
    })),
});
