import { z } from 'zod';
import { SchemaHookKind, schemaWithHook } from '../../../../../src/schemaWithHook';

export const varValSchema = z.union([
    z.string(),
    z.number(),
    z.null(),
]);

/**
 * TODO we might want to refactor the `status: Error` to a `status: 'error'` and `message: string`,
 * that way we can send the error over the wire easily without transformation logic.
 */
export const varSchema = z.union([
    z.object({
        status: z.literal('ok'),
        val: varValSchema,
    }).describe('a var that is "ok" (the value is usable)'),
    z.object({
        status: z.instanceof(Error),
        val: z.null(),
    }),
    z.object({
        status: z.literal('pending'),
        val: z.null(),
    }).describe('a var that is not usable'),
]).describe('an instance of a variable');

export const varNameSchema = schemaWithHook({
    schema: z.string().min(1),
    hook: SchemaHookKind.VarName,
});

export const optionalVarNameSchema = schemaWithHook({
    schema: z.string(),
    hook: SchemaHookKind.VarName,
});
