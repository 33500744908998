import { ChangeMapVal, FlatListChanges, ListItem, PendingListChanges, UpdateChange } from './use-pending-list-changes';
import isEqual from 'lodash.isequal';

type ListChangeFormats = 'plc' | 'flat' | 'map';
type GetListChangesOptions<Format extends ListChangeFormats = ListChangeFormats, PartialChanges extends boolean = true> = {
    /**
     * perform deep comparison to detect changes.
     * @default false
     */
    deep?: boolean;
    /**
     * @default 'map'
     */
    format?: Format;
    /**
     * whether updates should only contain changed properties and deletes should contain the full deleted object
     * @default true
     */
    partialChanges?: PartialChanges;
};

function sortChanges<T extends ListItem> (changes: T[]) {
    return changes.sort((c1, c2) => c1.id < c2.id ? -1 : 1);
}

export function getListChanges<T extends ListItem, PartialChanges extends boolean = true> (newList: T[], oldList: T[], options?: GetListChangesOptions<'plc', PartialChanges>): PendingListChanges<T, PartialChanges>;
export function getListChanges<T extends ListItem, PartialChanges extends boolean = true> (newList: T[], oldList: T[], options?: GetListChangesOptions<'flat', PartialChanges>): FlatListChanges<T, PartialChanges>;
export function getListChanges<T extends ListItem, PartialChanges extends boolean = true> (newList: T[], oldList: T[], options?: GetListChangesOptions<'map', PartialChanges>): Map<T['id'], ChangeMapVal<T, PartialChanges>>;
/**
 * This only works if the list items always have the same properties, so comparing objects works
 */
export function getListChanges<
    T extends ListItem,
    PartialChanges extends boolean = true,
> (
    newList: T[],
    oldList: T[],
    options?: GetListChangesOptions<ListChangeFormats, PartialChanges>
): PendingListChanges<T, PartialChanges> | FlatListChanges<T, PartialChanges> | Map<T['id'], ChangeMapVal<T, PartialChanges>> {
    const deep = options?.deep || false;
    const format = options?.format || 'map';
    const partialChanges = typeof options?.partialChanges === 'boolean' ? options.partialChanges : true;
    const changeMap = new Map<T['id'], ChangeMapVal<T, PartialChanges>>();

    const newListMap = new Map<T['id'], T>();
    // ensure there are no duplicate IDs
    newList.forEach(newItem => {
        if (newListMap.has(newItem.id)) {
            throw new Error(`Duplicate ID: ${newItem.id} in list`);
        }
        newListMap.set(newItem.id, newItem);
    });

    if (newList === oldList) {
        if (format === 'map') {
            return changeMap;
        } else if (format === 'flat') {
            return [];
        } else if (format === 'plc') {
            return { deletes: [], inserts: [], updates: [] };
        } else {
            const unknownFormat: never = format;
            throw new Error(`unsupported format: ${unknownFormat}`);
        }
    }

    const oldListMap = new Map<T['id'], T>(oldList.map(item => [ item.id, item ]));


    for (const [ newId, newItem ] of newListMap) {
        const oldItem = oldListMap.get(newId);
        if (oldItem) {
            // this item is not new. is this an update?
            let itemsEqual = true; // until shown otherwise

            /** a diff of changed properties between new and old item */
            const diff = { id: newItem.id } as UpdateChange<T, PartialChanges>;
            for (const key in newItem) {
                const valuesEqual = deep ? isEqual(newItem[key], oldItem[key]) : newItem[key] === oldItem[key];
                if (!valuesEqual) {
                    diff[key] = newItem[key];
                    itemsEqual = false;
                }
            }

            if (!itemsEqual) {
                changeMap.set(newId, { changeGroup: 'updates', item: partialChanges ? diff : newItem });
            }

            // we can ignore the item in the old list when we check for deleted items later
            oldListMap.delete(newId);
        } else {
            // this item is new, and should be an insert
            changeMap.set(newId, { changeGroup: 'inserts', item: newItem });
        }
    }

    // anything that remains in the old list has been deleted
    for (const [ oldId, oldItem ] of oldListMap) {
        // @ts-expect-error not sure how to relax tsc
        changeMap.set(oldId, { changeGroup: 'deletes', item: partialChanges ? { id: oldItem.id } : oldItem });
    }

    if (format === 'map') {
        return changeMap;
    } else if (format === 'flat') {
        return Array.from(changeMap.values()).sort((c1, c2) => c1.item.id < c2.item.id ? -1 : 1);
    } else {
        const changes: PendingListChanges<T, PartialChanges> = {
            deletes: [],
            inserts: [],
            updates: [],
        };
        for (const change of changeMap.values()) {
            // @ts-expect-error not sure how to relax tsc
            changes[change.changeGroup].push(change.item);
        }
        return {
            deletes: sortChanges(changes.deletes),
            inserts: sortChanges(changes.inserts),
            updates: sortChanges(changes.updates),
        };
    }
}
