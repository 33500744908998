import { z } from 'zod';

/** when something wants to reference an applet event, it should have an object with an `appletEventId` property */
export const appletEventRefSchema = z.object({
    appletEventId: z.string(),
});

export const appletEventDefSchema = z.object({
    appletEventId: z.string(),
    appletEventName: z.string(),
    description: z.string().optional(),
    // FUTURE: other config?
});
