import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { Component } from 'vue';
import { CSSProperties } from 'vue/types/jsx';

export type LinkedGridCellRenderer<LGC extends Latest.Screen.Designer.LinkedGridCell> = {
    standardProps: {
        cellCfg: LGC;
    };
    renderer: () => Promise<Component>;
};

type RenderGridWrapperStyle = {
    height: '100%';
    overflow: 'auto';
    gridArea: string;
    position: 'relative'; // the grid cell content is absolutely positioned within this so that the inset css property can be used to simulate margin
}

type Padding = {
    paddingTop: string;
    paddingLeft: string;
    paddingRight: string;
    paddingBottom: string;
}

type Border = {
    borderTop: string;
    borderLeft: string;
    borderRight: string;
    borderBottom: string;
}

type AllCellsSharedStyle = Padding & Border & {
    inset: string;
    fontFamily: string;
    position: 'absolute';
    borderRadius: string;
    backgroundColor: string;
}

export type RegularGridCellStyle = AllCellsSharedStyle & {
    display: 'flex';
    overflow: 'auto';
    alignItems: 'center';
    justifyContent: 'center';
};

type GenericInfoBoxTextStyle = {
    display: 'flex';
    fontSize: string;
    fontWeight: string;
    alignItems: string;
    justifyContent: string;
};

type InfoBoxGridCellStyle = {
    general: RegularGridCellStyle;
    header: GenericInfoBoxTextStyle & {
        height: '30%';
    };
    content: GenericInfoBoxTextStyle & {
        height: '70%';
    };
};

type SubGridCellStyle = AllCellsSharedStyle & {
    gap: '0px 0px';
    display: 'grid';
    gridTemplateRows: string;
    gridTemplateAreas: string;
    gridTemplateColumns: string;
};

type TabGroupCellStyle = {
    tabGroupStyle: AllCellsSharedStyle;
    tabHeaderStyle: { height: string; } | { width: string };
    // tab content does not get a width option since the way vertical tabs are rendered width doesnt do anything
    tabContentStyle: {
        height: string;
    };
}

const generatePattern = (rowNum: number, colNum: number, linkedGridCells: Latest.Screen.Designer.LinkedGridCell[]) => {
    let pattern = '';
    for (let rIdx = 0; rIdx < rowNum; rIdx++) {
        pattern += '"';
        for (let cIdx = 0; cIdx < colNum; cIdx++) {
            const lgc = linkedGridCells.find(lgc => lgc.coordinates.find(coord => coord.row === rIdx && coord.column === cIdx));
            if (cIdx === colNum - 1) {
                pattern += `lgc-${lgc?.id || '.'}" `;
            } else {
                pattern += `lgc-${lgc?.id || '.'} `;
            }
        }
    }
    return pattern;
};

export const generateRenderGridCellWrapperStyling = (linkedGridCellId: string): RenderGridWrapperStyle => {
    const style: RenderGridWrapperStyle = {
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        gridArea: `lgc-${linkedGridCellId}`,
    };
    return style;
};

export const generateRegularCellStyle = (linkedGridCellCfg: Latest.Screen.Designer.RegularGridCell | Latest.Screen.Designer.InfoBoxCell, backgroundColor: string, fontFamily: string): RegularGridCellStyle => {
    const style: RegularGridCellStyle = {
        fontFamily,
        backgroundColor,
        display: 'flex',
        overflow: 'auto',
        alignItems: 'center',
        position: 'absolute',
        justifyContent: 'center',
        paddingTop: `${linkedGridCellCfg.padding.top}px`,
        paddingLeft: `${linkedGridCellCfg.padding.left}px`,
        borderRadius: `${linkedGridCellCfg.borderRadius}px`,
        paddingRight: `${linkedGridCellCfg.padding.right}px`,
        paddingBottom: `${linkedGridCellCfg.padding.bottom}px`,
        borderTop: `${linkedGridCellCfg.border.top.width}px ${linkedGridCellCfg.border.top.style} ${linkedGridCellCfg.border.top.color}`,
        borderLeft: `${linkedGridCellCfg.border.left.width}px ${linkedGridCellCfg.border.left.style} ${linkedGridCellCfg.border.left.color}`,
        borderRight: `${linkedGridCellCfg.border.right.width}px ${linkedGridCellCfg.border.right.style} ${linkedGridCellCfg.border.right.color}`,
        borderBottom: `${linkedGridCellCfg.border.bottom.width}px ${linkedGridCellCfg.border.bottom.style} ${linkedGridCellCfg.border.bottom.color}`,
        inset: `
            ${linkedGridCellCfg.margin.top}px
            ${linkedGridCellCfg.margin.right}px
            ${linkedGridCellCfg.margin.bottom}px
            ${linkedGridCellCfg.margin.left}px
        `,
    };
    return style;
};

export const generateInfoBoxCellStyle = (infoBoxLinkedGridCellCfg: Latest.Screen.Designer.InfoBoxCell, backgroundColor: string, fontFamily: string): InfoBoxGridCellStyle => {
    const style: InfoBoxGridCellStyle = {
        general: generateRegularCellStyle(infoBoxLinkedGridCellCfg, backgroundColor, fontFamily),
        header: {
            height: '30%',
            display: 'flex',
            fontWeight: infoBoxLinkedGridCellCfg.additionalCellProperties.headerFontWeight,
            fontSize: `${infoBoxLinkedGridCellCfg.additionalCellProperties.headerFontSize}px`,
            alignItems: infoBoxLinkedGridCellCfg.additionalCellProperties.headerVerticalAlignment,
            justifyContent: infoBoxLinkedGridCellCfg.additionalCellProperties.headerHorizontalAlignment,
        },
        content: {
            height: '70%',
            display: 'flex',
            fontWeight: infoBoxLinkedGridCellCfg.additionalCellProperties.contentFontWeight,
            fontSize: `${infoBoxLinkedGridCellCfg.additionalCellProperties.contentFontSize}px`,
            alignItems: infoBoxLinkedGridCellCfg.additionalCellProperties.contentVerticalAlignment,
            justifyContent: infoBoxLinkedGridCellCfg.additionalCellProperties.contentHorizontalAlignment,
        },
    };
    return style;
};

export const generateSubGridStyle = (subGridCellCfg: Latest.Screen.Designer.SubGridCell | Latest.Screen.Designer.TabInstanceCell | Latest.Screen.Designer.RootGridLayoutCell, backgroundColor: string, filteredLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[], fontFamily: string): SubGridCellStyle => {
    const style: SubGridCellStyle = {
        fontFamily,
        gap: '0px 0px',
        backgroundColor,
        display: 'grid',
        position: 'absolute',
        paddingTop: `${subGridCellCfg.padding.top}px`,
        paddingLeft: `${subGridCellCfg.padding.left}px`,
        borderRadius: `${subGridCellCfg.borderRadius}px`,
        paddingRight: `${subGridCellCfg.padding.right}px`,
        paddingBottom: `${subGridCellCfg.padding.bottom}px`,
        gridTemplateRows: subGridCellCfg.rows.map(r => `${r.weight}fr`).join(' '),
        gridTemplateColumns: subGridCellCfg.columns.map(c => `${c.weight}fr`).join(' '),
        gridTemplateAreas: generatePattern(subGridCellCfg.rows.length, subGridCellCfg.columns.length, filteredLinkedGridCells),
        borderTop: `${subGridCellCfg.border.top.width}px ${subGridCellCfg.border.top.style} ${subGridCellCfg.border.top.color}`,
        borderLeft: `${subGridCellCfg.border.left.width}px ${subGridCellCfg.border.left.style} ${subGridCellCfg.border.left.color}`,
        borderRight: `${subGridCellCfg.border.right.width}px ${subGridCellCfg.border.right.style} ${subGridCellCfg.border.right.color}`,
        borderBottom: `${subGridCellCfg.border.bottom.width}px ${subGridCellCfg.border.bottom.style} ${subGridCellCfg.border.bottom.color}`,
        inset: `
            ${subGridCellCfg.margin.top}px
            ${subGridCellCfg.margin.right}px
            ${subGridCellCfg.margin.bottom}px
            ${subGridCellCfg.margin.left}px
        `,
    };
    return style;
};

export const generateTabGroupStyle = (tabGroup: Latest.Screen.Designer.TabGroupCell, backgroundColor: string, fontFamily: string): TabGroupCellStyle => {
    let tabHeaderRatio = 20;
    switch (tabGroup.additionalCellProperties.tabHeaderSize) {
        case 'hidden':
            tabHeaderRatio = 0;
            break;
        case 'small':
            tabHeaderRatio = 15;
            break;
        case 'medium':
            tabHeaderRatio = 20;
            break;
        case 'large':
            tabHeaderRatio = 25;
            break;
        case 'x-large':
            tabHeaderRatio = 30;
            break;
        default: {
            const unknownType: string = tabGroup.additionalCellProperties.tabHeaderSize;
            throw new Error(`Unknown tab header size "${unknownType}"`);
        }
    }

    const style: TabGroupCellStyle = {
        tabGroupStyle: {
            fontFamily,
            backgroundColor,
            position: 'absolute',
            paddingTop: `${tabGroup.padding.top}px`,
            paddingLeft: `${tabGroup.padding.left}px`,
            borderRadius: `${tabGroup.borderRadius}px`,
            paddingRight: `${tabGroup.padding.right}px`,
            paddingBottom: `${tabGroup.padding.bottom}px`,
            borderTop: `${tabGroup.border.top.width}px ${tabGroup.border.top.style} ${tabGroup.border.top.color}`,
            borderLeft: `${tabGroup.border.left.width}px ${tabGroup.border.left.style} ${tabGroup.border.left.color}`,
            borderRight: `${tabGroup.border.right.width}px ${tabGroup.border.right.style} ${tabGroup.border.right.color}`,
            borderBottom: `${tabGroup.border.bottom.width}px ${tabGroup.border.bottom.style} ${tabGroup.border.bottom.color}`,
            inset: `
                ${tabGroup.margin.top}px
                ${tabGroup.margin.right}px
                ${tabGroup.margin.bottom}px
                ${tabGroup.margin.left}px
            `,
        },
        tabHeaderStyle: tabGroup.additionalCellProperties.verticalTabs
            ? {
                width: `${tabHeaderRatio}%`,
            }
            : {
                height: `${tabHeaderRatio}%`,
            },
        tabContentStyle: {
            height: `${100 - tabHeaderRatio}%`,
        },
    };
    return style;
};

/** This function generates the styling that is needed around the root of the grid i.e. position relative, aspect-ratio, etc */
export const generateGridWrapperStyling = (): CSSProperties => {
    return {
        backgroundColor: 'rgb(210, 210, 210)',
    };
};
