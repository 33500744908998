export const messages = {
    authProvider: {
        name: 'Auth Providers',
        search: 'Search Auth Providers by Name',
    },
    noContexts: 'No contexts available for this account',
    signIn: 'Sign in',
    signInErrorFallback: 'Sign in failed',
    signInErrorWithReason: (signInResult: string) => `Sign in failed: ${signInResult}`,
    signInSuccess: 'Success!',
    signInTitle: 'Sign in to Argonaut',
    signOut: 'Sign out',
    signOutConfirmTitle: 'Are you sure you want to sign out?',
    signOutReasons: {
        default: 'You were signed out',
    },
};
