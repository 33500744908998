
import {
    PropType,
    computed,
    defineComponent,
    ref,
    watch,
} from 'vue';
import { ChangeLog, EntityTab } from './entity-route-util';
import { vuetify } from 'src/vue-plugins';
import FabBannerBtn from '@redviking/argonaut-core-ui/src/components/FabBannerBtn';
import ChangesDialog from './ChangesDialog.vue';

/**
 * experimental comp fn that makes edc resize to its contents.
 * disabled because it's a little buggy
 */
// function useResizer (heightPx: Ref<number | null>, routerView: Ref<Vue | null>) {
//     // debounce adjustments to min-height
//     let debounceInterval: number | null = null;
//     const resizeObserver = new ResizeObserver(entries => {
//         if (debounceInterval !== null) {
//             window.clearTimeout(debounceInterval);
//         }
//         debounceInterval = window.setTimeout(() => {
//             heightPx.value = entries[0].borderBoxSize[0].blockSize;
//             debounceInterval = null;
//         }, 100);
//     });

//     // alternative. this one will remove height control after 3s
//     // let heightTimeout: number | null = null;
//     // const resizeObserver = new ResizeObserver(entries => {
//     //     if (heightTimeout === null) {
//     //         heightPx.value = entries[0].borderBoxSize[0].blockSize;
//     //         heightTimeout = window.setTimeout(() => {
//     //             heightPx.value = null;
//     //             heightTimeout = null;
//     //         }, 3000);
//     //     }
//     // });

//     watch(routerView, (rvNew, rvOld) => {
//         if (rvOld) {
//             resizeObserver.unobserve(rvOld.$el);
//         }
//         if (rvNew) {
//             resizeObserver.observe(rvNew.$el);
//         }
//     });
//     onUnmounted(() => {
//         const rvv = routerView.value;
//         if (rvv) {
//             resizeObserver.unobserve(rvv.$el);
//         }
//     });
// }

export type EntityDetailStyling = {
    width?: string,
    margin?: {
        top?: number,
        left?: number,
        right?: number,
        bottom?: number,
    },
}

export const entityDetailPropsConfig = {
    open: { type: Boolean, required: false, default: false }, // This is never passed in so it is always false. needed at all?
    warnUnsaved: { type: Boolean, required: false, default: false },
    busy: { type: Boolean, required: false, default: false },
    activeTabIdx: { type: Number, required: false, default: 0 },
    /**
     * Whether the save button is shown in the banner. Disable this if saving UI is managed by child components
     */
    allowSaveFab: { type: Boolean, required: false, default: false },
    allowCancelFab: { type: Boolean, required: false, default: false },
    showBackBtn: { type: Boolean, required: false, default: false },
    /**
     * Whether to show the edit button in the banner when not editing
     */
    canEdit: { type: Boolean, required: false, default: false },
    /**
     * Whether to show the fork button in the banner when editing
     */
    canFork: { type: Boolean, required: false, default: false },
    editing: { type: Boolean, required: false, default: false },
    forking: { type: Boolean, required: false, default: false },
    useEntityDetailTabs: { type: Boolean, required: false, default: true },
    tabs: {
        type: [ Array, Function ] as PropType<EntityTab[] | (() => EntityTab[])>, required: true, default: () => [],
    },
    hasChanges: { type: Boolean, required: false, default: false },
    entityChangesDetail: {
        default: null,
        required: false,
        type: Object as PropType<ChangeLog.EntityChangesDetail>,
    },
    entityStyling: {
        default: null,
        required: false,
        type: Object as PropType<EntityDetailStyling>,
    },
};

export default defineComponent({
    name: 'EntityDetail',
    components: {
        FabBannerBtn,
        ChangesDialog,
    },
    props: entityDetailPropsConfig,
    emit: [ 'back', 'update:activeTabIdx', 'update:editing' ],
    setup (props) {

        const transitionName = ref('');

        const heightPx = ref<number | null>(null);


        // useResizer(heightPx, routerView);

        watch(() => props.activeTabIdx, (toIndex, fromIndex) => {
            if (toIndex < fromIndex) {
                transitionName.value = 'enter-left';
            } else if (toIndex > fromIndex) {
                transitionName.value = 'enter-right';
            } else {
                transitionName.value = '';
            }
        });

        return {
            transitionName,
            menuOpen: ref(false),
            changesDialogOpen: ref(false),
            mobile: computed(() => vuetify.framework.breakpoint.smAndDown),
            heightPx,
        };
    },
});

