import { AppletRuntimeCfg } from '../appletRuntimeCfg.zod';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { generateMaterialLookupMacroCells, generateMaterialLookupMacroVarps } from './decompose-material-lookup';
import { generateProcessMacroCells, generateProcessMacroVarps } from './decompose-process';
import { materialLookupCfgSchema } from '../../../types/mes/applet-designs/v3/macros/material-lookup.zod';
import { processMacroCfgSchema } from '../../../types/mes/applet-designs/v3/macros/process.zod';

// TODO This needs to be defined in a version file (v3) and imported using the latest namespace
export const macroMap = {
    process: processMacroCfgSchema,
    materialLookup: materialLookupCfgSchema,
};
export type MacroMap = typeof macroMap;

/**
 * given any type of macro config, this will execute the macro function and return the generated outputs
 */
export function decomposeMacro (macroCfg: Latest.Macros.AppletMacro): Latest.VarProviders.VarProvider[] {
    switch (macroCfg.type) {
        case 'materialLookup': {
            return generateMaterialLookupMacroVarps(macroCfg);
        }
        case 'process': {
            return generateProcessMacroVarps(macroCfg);
        }
        default: {
            // @ts-expect-error if this errors then we are missing a case
            const unknownType: never = macroCfg.type;
            throw new Error(`Unknown macro type "${unknownType}"`);
        }
    }
}

const decomposeMacroCells = (payload: {
    argoMacro: Latest.Macros.MaterialLookup | Latest.Macros.Process.Macro;
    linkedGridCell: Latest.Screen.Designer.RegularGridCell;
    allLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    allArgoCells: Latest.Screen.Cells.AppletCell[];
    macroTargetCell: Latest.Screen.Cells.MacroTargetCell;
}): {
    argoCells: Latest.Screen.Cells.AppletCell[];
    linkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
} => {
    switch (payload.argoMacro.type) {
        case 'process': {
            return generateProcessMacroCells({
                ...payload,
                argoMacro: payload.argoMacro,
            });
        }
        case 'materialLookup': {
            return generateMaterialLookupMacroCells({
                ...payload,
                argoMacro: payload.argoMacro,
            });
        }
        default: {
            // @ts-expect-error if this errors then we are missing a case
            const unknownType: never = macroCfg.type;
            throw new Error(`Unknown macro type "${unknownType}"`);
        }
    }
};

/**
 * Given a AppletRuntimeCfg, this will generate all the required cells for the applet to function and return the updated AppletRuntimeCfg
 */
export const generateAllRequiredCells = (cfg: AppletRuntimeCfg): AppletRuntimeCfg => {
    const returnCfg = { ...cfg };
    cfg.design.versionCfg.macros.forEach(macroCfg => {
        cfg.design.versionCfg.screens.forEach((s, screenIdx) => {
            s.cells.filter(c => c.type === 'macroTarget').forEach(c => {
                if (c.type === 'macroTarget' && c.attrs.macroId === macroCfg.id) {
                    const gridLayoutAspectRatios = s.gridLayoutAspectRatios;
                    for (const gridLayoutKey in gridLayoutAspectRatios) {
                        const gridLayout = s.gridLayoutAspectRatios[gridLayoutKey as keyof typeof s.gridLayoutAspectRatios];
                        const macroLgc = gridLayout.linkedGridCells.find(lgc => lgc.type === 'regular' && lgc.argoCellId === c.id);
                        if (macroLgc && macroLgc.type === 'regular') {
                            const {
                                argoCells,
                                linkedGridCells,
                            } = decomposeMacroCells({
                                argoMacro: macroCfg,
                                linkedGridCell: macroLgc,
                                macroTargetCell: c,
                                allArgoCells: [ ...s.cells ],
                                allLinkedGridCells: [ ...gridLayout.linkedGridCells ],
                            });
                            const returnScreen = returnCfg.design.versionCfg.screens[screenIdx];
                            if (!returnScreen) {
                                throw new Error('Could not find screen');
                            }
                            returnScreen.cells = [ ...argoCells ];
                            returnScreen.gridLayoutAspectRatios[gridLayoutKey as keyof typeof returnScreen.gridLayoutAspectRatios].linkedGridCells = [ ...linkedGridCells ];
                        }
                    }
                }
            });
        });
    });
    return returnCfg;
};
