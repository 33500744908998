/* eslint-disable function-paren-newline */
import { z } from 'zod';
import { DeployParamKind, deployParamSchema } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { varOrConstSchema } from '../var-providers/var-provider.zod';
import { strVarSchema } from '../var-providers/var.zod';

const sparkplugCfgSchema = z.object({
  nodeId: z.string(),
  version: z.string(),
  groupId: z.string(),
  deviceId: z.string(),
});

export const sparkplugAppletNodeCfgManualSchema = sparkplugCfgSchema.extend({
    deviceId: varOrConstSchema(strVarSchema),
    type: z.literal('manual'),
});

const sparkplugAppletNodeCfgDeployParamSchema = deployParamSchema(z.object({
    deployParamKind: z.literal(DeployParamKind.sparkplugNodeCfg),
    deployParamId: z.string(),
    type: z.literal('deployParam'),
}));

export const sparkplugAppletNodeCfgSchema = z.union([
    sparkplugAppletNodeCfgManualSchema.strict(),
    sparkplugAppletNodeCfgDeployParamSchema,
]);
