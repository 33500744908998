import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

export function getUnlinkedCells (opts: {
    screen: Latest.Screen.Config,
}): Latest.Screen.Config['cells'] {
    const allLinkedGridCells = Object.values(opts.screen.gridLayoutAspectRatios).flatMap(gridLayout => gridLayout.linkedGridCells);
    /** all cells in the screen that are not found in any of the linked grid cells */
    const unlinkedCells = opts.screen.cells.filter(cell => !allLinkedGridCells.find(lgc => lgc.type === 'regular' && lgc.argoCellId === cell.id));
    return unlinkedCells;
}

export function getUnlinkedCell (opts: {
    screen: Latest.Screen.Config,
}): Latest.Screen.Config['cells'][number] | null {
    const allLinkedGridCells = Object.values(opts.screen.gridLayoutAspectRatios).flatMap(gridLayout => gridLayout.linkedGridCells);
    /** the first cell in the screen that is not found in any of the linked grid cells */
    const unlinkedCell = opts.screen.cells.find(cell => !allLinkedGridCells.find(lgc => lgc.type === 'regular' && lgc.argoCellId === cell.id));
    return unlinkedCell || null;
}
