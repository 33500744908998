import {
    varpConfigBase,
} from './var-provider.zod';
import { z } from 'zod';
import { optionalVarNameSchema, varNameSchema } from '../var-providers/var.zod';
import { varConditionSchema } from './conditional.zod';

export const mqttPublishProviderConfigSchema = varpConfigBase.extend({
    type: z.literal('mqttPublish'),
    attrs: z.object({
        topic: z.discriminatedUnion('type', [
            z.object({
                type: z.literal('const'),
                val: z.string().min(1),
            }),
            z.object({
                type: z.literal('var'),
                var: varNameSchema,
            }),
        ]),

        condition: varConditionSchema.refine(val => {
            return val.comparisons.length > 0;
        }, { message: 'condition must have at least one comparison' }),

        payload: z.discriminatedUnion('type', [
            z.object({
                type: z.literal('const'),
                val: z.string().min(1),
            }),
            z.object({
                type: z.literal('var'),
                var: varNameSchema,
            }),
        ]),

        payloadType: z.enum([ 'string', 'json' ]).default('string'),

        /**
         * after a successful publish, this variable will be set to the current timestamp.
         *
         * if the publish fails, this variable will be set to the error message.
         *
         * initially, this var will be pending since it represents the last timestamp of a successful publish.
         */
        publishResultVar: optionalVarNameSchema,

        qos: z.union([
            z.literal(0),
            z.literal(1),
            z.literal(2),
        ]).default(0),

        retain: z.boolean().default(false),
    }),
});
