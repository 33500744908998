/* eslint sort-keys: error */
/* eslint-disable max-lines */
import { v4 as uuidv4 } from 'uuid';
import type { AppletDesignMenuItem } from '@redviking/argonaut-core-ui/src/applet/design/util/AppletDesignMenuItem';
import type { Component } from 'vue';
import { messages } from 'src/i18n/i18n';
import { DeployParamKind } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { validateComparisons } from '@redviking/argonaut-core-ui/src/mes/lib/validate-comparison';
import { gatherComparisonInputs } from '@redviking/argonaut-util/src/mes/gather-comparison-inputs';
import type { EntityTypeMap } from 'types/entity';
import { CausalError } from '@redviking/causal-error';
import cellTypeMap from '../cells/cellTypeMap';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
type PartialAttrs<T> = T extends object ? { [P in keyof T]?: Partial<T[P]> } : T;

export interface MacroMapItem<M extends Latest.Macros.AppletMacro = Latest.Macros.AppletMacro> extends AppletDesignMenuItem<M['type']> {
    defaultMacro: (cfg?: PartialAttrs<M>) => M;
    validator: (macroCfg: M, entityData: EntityTypeMap['appletDesignVersion'], allVars: string[]) => void;
    tab: () => Promise<Component>;
    tree?: () => Promise<Component>;
    icon: string;
    cellTarget: keyof typeof cellTypeMap;

    /**
     * component to render when a macro's varp target is activated for configuration.
     *
     * only needed if the macro implementation fn returns varps
     */
    varpTarget: {
        icon: string;
        name: (cfg: Latest.Macros.AppletMacro) => string;
    };
}

export type MacroTypeMap = {
    [macroTypeKey in Latest.Macros.AppletMacro['type']]: MacroMapItem<Latest.Macros.AppletMacro & { type: macroTypeKey }>;
};

export const macroTypeMap: MacroTypeMap = {
    materialLookup: {
        cellTarget: 'materialLookup',
        defaultMacro: cfg => {
            const macro: Latest.Macros.MaterialLookup = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    materialLookupAttrs: cfg?.attrs?.materialLookupAttrs || {
                        filterConditions: [
                            {
                                boolOperation: 'and',
                                comparisons: [],
                            },
                        ],
                        matClassId: '',
                        outputVars: {
                            materialId: 'material-id-var',
                            sn: 'serial-number-var',
                            udfs: [],
                        },
                        refreshCondition: {
                            boolOperation: 'and',
                            comparisons: [],
                        },
                        udfColsToShow: [],
                    },

                },
                id: uuidv4(),
                name: '',
                type: 'materialLookup',
            };
            return macro;
        },
        description: messages.mes.cells.materialLookup.description,
        icon: 'mdi-nut',
        tab: () => import('src/applet/design/macros/material-lookup/MaterialLookup.tab.macro.vue').then(m => m.default),
        text: messages.mes.cells.materialLookup.title,
        type: 'materialLookup',
        validator: (macro, _, allVars) => {
            const materialLookupAttrs = macro.attrs.materialLookupAttrs;

            if (materialLookupAttrs.outputVars.materialId && !allVars.includes(materialLookupAttrs.outputVars.materialId)) {
                throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({
                    cellLabel: macro.name,
                    cellType: macro.type,
                    varName: materialLookupAttrs.outputVars.materialId,
                }));
            }

            if (materialLookupAttrs.outputVars.sn && !allVars.includes(materialLookupAttrs.outputVars.sn)) {
                throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({
                    cellLabel: macro.name,
                    cellType: macro.type,
                    varName: materialLookupAttrs.outputVars.sn,
                }));
            }

            for (const udf of materialLookupAttrs.outputVars.udfs) {
                if (!allVars.includes(udf.varName)) {
                    throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({
                        cellLabel: macro.name,
                        cellType: macro.type,
                        varName: udf.varName,
                    }));
                }
            }

            // Do not need to validate macro conditions since the only thing the validate function does it check for var names in conditions that compare vars
            // Material lookup conditions are not allowed to compare vars

            try {
                const inputs = gatherComparisonInputs(macro.attrs.materialLookupAttrs.refreshCondition.comparisons);
                for (const input of inputs) {
                    if (!allVars.includes(input)) {
                        throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({
                            cellLabel: macro.name,
                            cellType: macro.type,
                            varName: input,
                        }));
                    }
                }
                validateComparisons(macro.attrs.materialLookupAttrs.refreshCondition.comparisons);
            } catch (err) {
                throw new CausalError('Refresh condition is invalid', err);
            }
        },
        varpTarget: {
            icon: 'mdi-nut',
            name: cfg => cfg.name ? `${cfg.name} (State)` : 'Material Lookup (State)',
        },
    },
    process: {
        cellTarget: 'process',
        defaultMacro: cfg => {
            const macro: Latest.Macros.Process.Macro = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    collectTrigger: cfg?.attrs?.collectTrigger || {
                        type: 'var',
                        var: '',
                    },
                    dataCollection: cfg?.attrs?.dataCollection || [],
                    defaultSparkplugCfg: cfg?.attrs?.defaultSparkplugCfg || {
                        deviceId: {
                            type: 'const',
                            val: {
                                status: 'ok',
                                type: 'str',
                                val: '',
                            },
                        },
                        groupId: '',
                        nodeId: '',
                        type: 'manual',
                        version: '',
                    },
                    matIdVar: cfg?.attrs?.matIdVar || '',
                    params: cfg?.attrs?.params || [],
                    processLocation: cfg?.attrs?.processLocation || {
                        deployParamId: 'Location',
                        deployParamKind: DeployParamKind.location,
                    },
                    processRevId: cfg?.attrs?.processRevId || '',
                    stateVars: cfg?.attrs?.stateVars || {
                        bad: '',
                        good: '',
                        required: '',
                        specId: '',
                        specName: '',
                        status: '',
                        validationError: '',
                    },
                },
                id: uuidv4(),
                name: '',
                type: 'process',
            };
            return macro;
        },
        description: 'View or modify the state of a process',
        icon: '$argo-process',
        tab: () => import('src/applet/design/macros/process/Process.macro.tab.vue').then(m => m.default),
        text: 'Process',
        type: 'process',
        validator: (macro) => {
            if (!macro.attrs.processRevId) {
                throw new Error('No process selected');
            }
            if (!macro.attrs.matIdVar) {
                throw new Error('Material ID variable is required');
            }
            const collectTrigger = macro.attrs.collectTrigger;
            if (collectTrigger.type === 'sparkplug') {
                if (!collectTrigger.varpCfg.metric) {
                    throw new Error('Metric is not defined for the collection trigger');
                }
            } else if (collectTrigger.type === 'var') {
                if (!collectTrigger.var) {
                    throw new Error('Variable is not given for the collection trigger');
                }
            } else if (collectTrigger.type === 'btn') {
                // noop
            } else if (collectTrigger.type === 'conditional') {
                if (!collectTrigger.conditional.comparisons) {
                    throw new Error('Condition is not given for the collection trigger');
                }
                validateComparisons(collectTrigger.conditional.comparisons);
            } else {
                // @ts-expect-error if this errors then a type is missing
                const unknownType: never = collectTrigger.type;
                throw new Error(`Unknown collect trigger type "${unknownType}"`);
            }

            for (const dc of macro.attrs.dataCollection) {
                if (dc.source === 'localInput') {
                    // noop
                } else if (dc.source === 'sparkplug') {
                    if (!dc.varpCfg.metric) {
                        throw new Error('Metric is required for data collection PDEs using sparkplug');
                    }
                } else if (dc.source === 'var') {
                    if (!dc.var) {
                        throw new Error('Variable is not set for data collection source');
                    }
                } else {
                    // @ts-expect-error if this errors then a source is missing
                    const unknownSource: never = dc.source;
                    throw new Error(`Unknown data collection source "${unknownSource}"`);
                }
            }

            for (const param of macro.attrs.params) {
                if (param.target === 'sparkplug') {
                    if (!param.varpCfg.metric) {
                        throw new Error('Destination metric is required for param output using a sparkplug target');
                    }
                } else if (param.target === 'var') {
                    // noop
                } else {
                    // @ts-expect-error if this errors then a target is missing
                    const unknownTarget: never = param.target;
                    throw new Error(`Unknown param target "${unknownTarget}"`);
                }
            }
        },
        varpTarget: {
            icon: '$argo-process',
            name: cfg => cfg.name || 'Process',
        },
    },
};

export const macroTypeMapItems = Object.keys(macroTypeMap).map(k => macroTypeMap[k as Latest.Macros.AppletMacroType]);

export default macroTypeMap;
