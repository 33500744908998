import { v4 as uuidv4 } from 'uuid';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

export const generateMaterialLookupMacroVarps = (cfg: Latest.Macros.MaterialLookup): Latest.VarProviders.VarProvider[] => {
    const localProvider: Latest.VarProviders.Local.Provider = {
        id: 'material_lookup_state',
        type: 'local',
        attrs: { vars: [] },
        outputs: [],
        label: `Material Lookup state ${cfg.name}`,
    };
    if (cfg.attrs.materialLookupAttrs.outputVars.materialId) {
        localProvider.outputs.push(cfg.attrs.materialLookupAttrs.outputVars.materialId);
        localProvider.attrs.vars.push({
            type: 'localVar',
            name: cfg.attrs.materialLookupAttrs.outputVars.materialId,
            varCfg: {
                val: null,
                type: 'null',
                status: 'ok',
            },
        });
    }
    if (cfg.attrs.materialLookupAttrs.outputVars.sn) {
        localProvider.outputs.push(cfg.attrs.materialLookupAttrs.outputVars.sn);
        localProvider.attrs.vars.push({
            type: 'localVar',
            name: cfg.attrs.materialLookupAttrs.outputVars.sn,
            varCfg: {
                val: null,
                type: 'null',
                status: 'ok',
            },
        });
    }
    for (const udfCol of cfg.attrs.materialLookupAttrs.outputVars.udfs) {
        localProvider.outputs.push(udfCol.varName);
        localProvider.attrs.vars.push({
            type: 'localVar',
            name: udfCol.varName,
            varCfg: {
                val: null,
                type: 'null',
                status: 'ok',
            },
        });
    }

    return [ localProvider ];
};

export const generateMaterialLookupMacroCells = (payload: {
    argoMacro: Latest.Macros.MaterialLookup;
    linkedGridCell: Latest.Screen.Designer.RegularGridCell;
    allLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    allArgoCells: Latest.Screen.Cells.AppletCell[];
    macroTargetCell: Latest.Screen.Cells.MacroTargetCell;
}): {
    argoCells: Latest.Screen.Cells.AppletCell[];
    linkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
} => {
    const subGrid: Latest.Screen.Designer.SubGridCell = {
        name: '',
        elevation: 0,
        type: 'subGrid',
        borderRadius: 0,
        rows: [ { weight: 1 } ],
        columns: [ { weight: 1 } ],
        id: payload.linkedGridCell.id,
        margin: payload.linkedGridCell.margin,
        padding: payload.linkedGridCell.padding,
        coordinates: payload.linkedGridCell.coordinates,
        parentGridId: payload.linkedGridCell.parentGridId,
        backgroundColor: payload.linkedGridCell.backgroundColor,
        backgroundColorControlVar: payload.linkedGridCell.backgroundColorControlVar,
        border: {
            top: { width: 1, color: 'black', style: 'none' },
            left: { width: 1, color: 'black', style: 'none' },
            right: { width: 1, color: 'black', style: 'none' },
            bottom: { width: 1, color: 'black', style: 'none' },
        },
    };
    payload.allLinkedGridCells = [
        ...payload.allLinkedGridCells.filter(lgc => lgc.id !== payload.linkedGridCell.id),
        subGrid,
    ];

    const materialLookupArgoCell: Latest.Screen.Cells.MaterialLookup.Cell = {
        type: 'materialLookup',
        id: `${payload.argoMacro.id}_cell_materialLookup`,
        attrs: {
            parentCellId: payload.macroTargetCell.attrs.parentCellId,
            matClassId: payload.argoMacro.attrs.materialLookupAttrs.matClassId,
            udfColsToShow: payload.argoMacro.attrs.materialLookupAttrs.udfColsToShow,
            filterConditions: payload.argoMacro.attrs.materialLookupAttrs.filterConditions,
            refreshCondition: payload.argoMacro.attrs.materialLookupAttrs.refreshCondition,
            outputVars: {
                sn: payload.argoMacro.attrs.materialLookupAttrs.outputVars.sn,
                materialId: payload.argoMacro.attrs.materialLookupAttrs.outputVars.materialId,
                udfs: payload.argoMacro.attrs.materialLookupAttrs.outputVars.udfs.map(udf => ({
                    udfColumnId: udf.udfColumnId,
                    varName: udf.varName || '',
                })),
            },
        },
    };

    const materialLookupCellLgc: Latest.Screen.Designer.RegularGridCell = {
        name: '',
        id: uuidv4(),
        elevation: 0,
        type: 'regular',
        borderRadius: 0,
        parentGridId: subGrid.id,
        backgroundColor: '#0000',
        backgroundColorControlVar: null,
        argoCellId: materialLookupArgoCell.id,
        coordinates: [ { column: 0, row: 0 } ],
        margin: { top: 0, right: 0, bottom: 0, left: 0 },
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        border: {
            top: { width: 1, color: 'black', style: 'none' },
            left: { width: 1, color: 'black', style: 'none' },
            right: { width: 1, color: 'black', style: 'none' },
            bottom: { width: 1, color: 'black', style: 'none' },
        },
    };
    payload.allArgoCells.push(materialLookupArgoCell);
    payload.allLinkedGridCells.push(materialLookupCellLgc);

    return {
        argoCells: payload.allArgoCells,
        linkedGridCells: payload.allLinkedGridCells,
    };
};
