
import {
    computed,
    defineComponent,
    ref,
} from 'vue';
import {
    vuetify,
} from '../../vue-plugins';

export default defineComponent({
    name: 'FabBannerBtn',
    props: {
        icon: {
            type: String,
            required: false,
            default: 'mdi-playlist-plus',
        },
        tooltip: {
            type: String,
            required: false,
            default: '',
        },
        color: {
            type: String,
            required: false,
            default: 'secondary',
        },
        subIcon: { type: String, required: false, default: '' },
        order: { type: Number, required: false, default: 1 },
        enabled: { type: Boolean, required: false, default: true },
    },
    setup () {
        const mobile = computed<boolean>(() => {
            return vuetify.framework.breakpoint.smAndDown;
        });
        return {
            appBarHeight: computed(() => mobile.value ? 56 : 64),
            btn: ref(''),
            showTooltip: ref(false),
            mobile: computed(() => vuetify.framework.breakpoint.smAndDown),
        };
    },
});
