import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';
import z from 'zod';
import { varConditionSchema } from '../var-providers/conditional.zod';
import { varNameSchema } from '../var-providers/var.zod';

const processCellAttrsSchema = cellCfgAttrsBaseSchema.extend({
    processName: varNameSchema,
    stepCount: varNameSchema,
    passedSteps: varNameSchema,
    failedSteps: varNameSchema,
    status: varNameSchema,
    matId: varNameSchema,
    specId: varNameSchema,
    showMedia: z.boolean().default(false),
    collectTriggerVar: varNameSchema.optional(),
    /**
     * Variable which holds the id of the current material process
     */
    matProcStateIdVar: varNameSchema,
    /**
     * Variable which holds the current status of the process
     */
    currStatusVar: varNameSchema,
    /**
     * Variable which tells the process that the material status has changed and needs to be updated
     */
    matProcStateResponceVar: varNameSchema.optional(),
    /**
     * Variable which tells the process that the material is able to have a process report run on it
     */
    matProcStateAvailVar: varNameSchema.optional(),
    /**
     * Condition which, when true, tells the cell to refetch the process status
    */
    refetchCondition: varConditionSchema.optional(),
});

export const processCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('process'),
    attrs: processCellAttrsSchema,
    modifiers: z.record(z.enum([
        'stepCount',
        'passedSteps',
        'failedSteps',
        'status',
        'matProcStateResponceVar',
        'matProcStateAvailVar',
        'matId',
        'specId',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
