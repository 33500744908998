import z from 'zod';
import { varNameSchema } from '../var-providers/var.zod';

export const baseOperandSchema = z.object({
    type: z.string(),
});

const operandSchemas = {
    var: baseOperandSchema.extend({ type: z.literal('var'), varName: varNameSchema }),
    str: baseOperandSchema.extend({ type: z.literal('str'), val: z.string() }),
    num: baseOperandSchema.extend({ type: z.literal('num'), val: z.number() }),
    type: baseOperandSchema.extend({ type: z.literal('type'), val: z.enum([ 'number', 'string', 'null' ]) }),
};

export const varComparisonOperandSchema = z.discriminatedUnion('type', [
    operandSchemas.var,
    operandSchemas.str,
    operandSchemas.num,
    operandSchemas.type,
]);

export const baseComparisonSchema = z.object({
    not: z.boolean().default(false),
    type: z.string(),
    operands: z.array(baseOperandSchema),
});

const varComparisonSchemas = {
    eq: baseComparisonSchema.extend({
        type: z.literal('eq'),
        operands: z.tuple([
            operandSchemas.var,
            z.discriminatedUnion('type', [
                operandSchemas.var,
                operandSchemas.str,
                operandSchemas.num,
            ]),
        ]),
    }).describe('resolves only if both operands are not null/NaN, and normalized values match'),
    regexp: baseComparisonSchema.extend({
        type: z.literal('regexp'),
        operands: z.tuple([
            operandSchemas.var,
            operandSchemas.str,
        ]),
    }).describe('resolves only if variable is not null and matches string pattern'),
    isset: baseComparisonSchema.extend({
        type: z.literal('isset'),
        operands: z.tuple([
            operandSchemas.var,
        ]),
    }).describe('resolves only if variable is not null or empty string'),
    lt: baseComparisonSchema.extend({
        type: z.literal('lt'),
        operands: z.tuple([
            operandSchemas.var,
            z.discriminatedUnion('type', [
                operandSchemas.var,
                operandSchemas.num,
            ]),
        ]),
    }),
    gt: baseComparisonSchema.extend({
        type: z.literal('gt'),
        operands: z.tuple([
            operandSchemas.var,
            z.discriminatedUnion('type', [
                operandSchemas.var,
                operandSchemas.num,
            ]),
        ]),
    }),
    type: baseComparisonSchema.extend({
        type: z.literal('type'),
        operands: z.tuple([
            operandSchemas.var,
            operandSchemas.type,
        ]),
    }),
    changed: baseComparisonSchema.extend({
        type: z.literal('changed'),
        okOnly: z.boolean().default(true),
        operands: z.tuple([
            operandSchemas.var,
        ]),
    }),
};

const varComparisonSchema = z.discriminatedUnion('type', [
    varComparisonSchemas.eq,
    varComparisonSchemas.regexp,
    varComparisonSchemas.isset,
    varComparisonSchemas.lt,
    varComparisonSchemas.gt,
    varComparisonSchemas.type,
    varComparisonSchemas.changed,
]);

const baseConditionSchema = z.object({
    /** how to evaluate the comparisons. `and` -> "all", `or` -> "any" */
    boolOperation: z.enum([ 'and', 'or' ]),
    comparisons: z.array(baseComparisonSchema),
});

export const varConditionSchema = baseConditionSchema.extend({
    comparisons: z.array(varComparisonSchema),
});
