import {
    AddRoleDocument,
    GetRoleDocument,
    UpdateRoleDocument,
} from '@redviking/argonaut-core-ui/types/db';
import { accessor } from '../store';
import { Route } from 'vue-router';
import { RouterViewSlider } from '../components/RouterViewSlider';
import { RouteEntityDetail } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { messages } from 'src/i18n/i18n';
import { validateRole, validateRoleSelectAcl, validateRoleUpdateAcl, validateRoleUserSelectAcl } from './roles.validations';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';
import {
    type EntityModifyParameters,
    type ExtendedEntityParameters,
    entityDetailRoute,
} from '@redviking/argonaut-core-ui/src/components/EntityDetail/entity-route-util';
import { entityPageBannerTitle } from 'src/util/formatting';
import { getListChanges } from 'src/util/composables/watch-list-changes';
import { userIsAdmin } from '@redviking/argonaut-core-ui/src/auth';

export default [
    {
        path: '/roles',
        name: 'role-base',
        redirect: { name: 'roles' },
        component: RouterViewSlider,
        meta: {
            navigationGuard: () => validateRoleSelectAcl(),
            navbarTitle: messages.roles.titles.nav,
            navbarIcon: 'mdi-badge-account',
            transition: 'horizontal',
        },
        children: [
            {
                name: 'roles',
                path: '',
                component: () => import(/* webpackChunkName: "roles" */ './Roles.view.vue'),
                meta: {
                    pageBannerTitle: messages.roles.titles.pageBannerTitle,
                    pageBannerSubtitle: messages.roles.titles.pageBannerSubtitle,
                },
            },
            entityDetailRoute<'role'>({
                path: ':roleId',
                name: 'roleMaintenence',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'roleSettings', params: to.params }),
                meta: {
                    returnRoute: (route: Route) => ({ name: 'roles', query: route.query }),
                    entityType: 'role',
                    canEdit: () => {
                        const role = accessor.entityAsType('role');
                        return (Boolean(role?.context_id) && validateRoleUpdateAcl()) || userIsAdmin();
                    },
                    getEntity: async (to) => {
                        if (to.query.mode === 'create') {
                            return {
                                entity: {
                                    id: to.params.roleId,
                                    context_id: '',
                                    name: '',
                                    enabled: true,
                                    acls: [],
                                },
                            };
                        } else {
                            const { role: [ role ] } = await gqlClient.request({
                                document: GetRoleDocument,
                                variables: {
                                    queryFilter: {
                                        id: { _eq: to.params.roleId },
                                    },
                                },
                            });

                            if (!role) {
                                throw new Error('Could not find role');
                            }

                            return {
                                entity: {
                                    id: role.id || '',
                                    context_id: role.context_id,
                                    name: role.name || '',
                                    enabled: role.enabled,
                                    acls: role.acls.map(acl => ({
                                        id: acl.id,
                                        role_id: acl.role_id,
                                        acl_id: acl.acl_id,
                                    })),
                                },
                            };
                        }
                    },
                    saveEntity: async (payload: ExtendedEntityParameters<'role'>) => {
                        const {
                            entity: role,
                            oldEntity: originalRole,
                        } = payload;

                        const roleAclPlc = getListChanges(role.acls, originalRole?.acls || [], { deep: true, format: 'plc' });
                        validateRole(role);

                        await gqlClient.request({
                            document: UpdateRoleDocument,
                            variables: {
                                roleId: role.id,
                                role: {
                                    name: role.name,
                                    enabled: role.enabled,
                                },
                                insertRoleAcls: roleAclPlc.inserts.map(roleAcl => ({
                                    id: roleAcl.id,
                                    acl_id: roleAcl.acl_id,
                                    role_id: roleAcl.role_id,
                                })),
                                deleteRoleAclsBoolExp: {
                                    id: { _in: roleAclPlc.deletes.map(roleAcl => roleAcl.id) },
                                },
                            },
                        });
                        Notify.win(messages.roles.role.notifySaved);
                    },
                    createEntity: async (payload: EntityModifyParameters<'role'>) => {
                        const role = payload.entity;
                        validateRole(role);

                        await gqlClient.request({
                            document: AddRoleDocument,
                            variables: {
                                role: {
                                    id: role.id,
                                    name: role.name,
                                    enabled: role.enabled,
                                    acls: {
                                        data: role.acls.map(roleAcl => ({
                                            id: roleAcl.id,
                                            acl_id: roleAcl.acl_id,
                                        })),
                                    },
                                },
                            },
                        });

                        Notify.win(messages.roles.role.notifySaved);
                    },
                    pageBannerTitle: () => entityPageBannerTitle('role', accessor.entityAsType('role')?.name),
                },
                children: [
                    {
                        path: 'settings',
                        name: 'roleSettings',
                        component: () => import(/* webpackChunkName: "roles" */ './RoleSettings.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: messages.settings,
                                icon: 'mdi-cog',
                            },
                        },
                    },
                    {
                        path: 'role-users',
                        name: 'roleUsers',
                        component: () => import(/* webpackChunkName: "roles" */ './RoleUsers.view.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Users',
                                icon: 'mdi-account-multiple',
                            },
                            navigationGuard: () => validateRoleUserSelectAcl(),
                        },
                    },
                ],
            }),
        ],
    },
];
