import * as esprima from 'esprima';
import ts from 'typescript';
import { Latest } from '../../types/mes/applet-designs/appletDesign.latest.zod';

export const tsCompilerOptions: ts.CompilerOptions = {
    target: 4, // ES2017, see the enum type
    allowNonTsExtensions: true,
    allowUnreachableCode: false,
    checkJs: true,
    noEmit: true, // only check the script
    types: [], // avoid pulling from @types/*
    noLib: true, // can't include the entire lib, because we're restricting it with appletLib
};

export interface ScriptContentUpdatePayload {
    scriptContent: string;
    varsInUse: string[];
}

export function getReferencedAppletVars (script: string): string[] {
    const referencedAppletVars: string[] = [];
    try {
        esprima.parseScript(script, {}, node => {
            if (node.type === 'MemberExpression' && node.property.type === 'Identifier') {
                const maybeAppletVars = node.object;
                if (
                    maybeAppletVars.type === 'MemberExpression' &&
                    maybeAppletVars.object.type === 'Identifier' &&
                    maybeAppletVars.object.name === 'applet' &&
                    maybeAppletVars.property.type === 'Identifier' &&
                    maybeAppletVars.property.name === 'vars'
                ) {
                    // "applet.vars.__" confirmed
                    referencedAppletVars.push(node.property.name);
                }
            }
        });
    } catch {
        // if there is a parse error, we can't determine the vars in use.
        return [];
    }
    return referencedAppletVars;
}

export function generateAppletVarLib (availableVars: string[]) {
    const varTyps: {
        [key in Latest.OkAppletVarType]: string;
    } = {
        str: 'string',
        num: 'number',
        null: 'null',
        bool: 'boolean',
    };
    return `
        type AppletVar = ${availableVars.map(varName => `'${varName}'`).join(' | ')};
        declare const applet: {
            vars: {
                [varName in AppletVar]: ${Object.values(varTyps).join(' | ')},
            },
        };
    `;
}

// This function is intended to generate the same type as `generateAppletVarLib` but using generic types instead of applet related items like variable names
export function generateGenericAppletVarLib () {
    const varTyps: {
        [key in Latest.OkAppletVarType]: string;
    } = {
        str: 'string',
        num: 'number',
        null: 'null',
        bool: 'boolean',
    };
    return `
        declare const applet: {
            vars: Record<string, ${Object.values(varTyps).join(' | ')}>,
        };
    `;
}

// these are not real, they just provide a consistent identifier for multiple packages.
export const argoScriptPath = 'file:///argo-script.js';
export const appletLibPath = 'file:///applet-lib.d.ts';
export const appletVarsPath = 'file:///applet-vars.d.ts';
