import { z } from 'zod';
import { varNameSchema } from './var.zod';
import { varpConfigBase } from './var-provider.zod';

export const lockingProviderSchema = varpConfigBase.extend({
    type: z.literal('locking'),
    attrs: z.object({
        vars: z.array(z.object({
            inVarName: varNameSchema,
            outVarName: varNameSchema,
        })),
        lockingVar: varNameSchema,
    }),
});
