import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

import z from 'zod';
import { varNameSchema } from '../var-providers/var.zod';

export const processMediaCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('processMedia'),
    attrs: cellCfgAttrsBaseSchema.extend({
        specIdVar: varNameSchema,
        passVar: varNameSchema,
    }),
});
