import { z } from 'zod';
import { varNameSchema } from './var.zod';
import { varpConfigBase } from './var-provider.zod';

export const varStatusProviderSchema = varpConfigBase.extend({
    type: z.literal('varStatus'),
    inputs: varNameSchema.array(),
    attrs: z.object({
        inputVar: z.string(),
        outputVar: z.string(),
    }),
});
