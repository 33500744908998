import { z } from 'zod';
import { varNameSchema } from '../var-providers/var.zod';
import { varpConfigBase } from './var-provider.zod';

export const formattedProviderSchema = varpConfigBase.extend({
    type: z.literal('formatted'),
    attrs: z.object({
        inputVars: z.array(varNameSchema),
        stringTemplate: z.string(),
        outputVar: varNameSchema,
        /**
         * if true, then the output will always have an ok status,
         * and if any inputs has an error status then that error's
         * message will be used instead of the input's val
         *
         * hidden option, currently only used by the process macro for building error text to display
         *
         * @default false
         */
        usingErrors: z.boolean().optional(),
    }),
});
