import {
    varpConfigBase,
} from './var-provider.zod';
import { z } from 'zod';
import { optionalVarNameSchema, varNameSchema } from './var.zod';

export const mqttSubscribeProviderConfigSchema = varpConfigBase.extend({
    type: z.literal('mqttSubscribe'),
    attrs: z.object({
        topic: z.discriminatedUnion('type', [
            z.object({
                type: z.literal('const'),
                val: z.string().regex(/^[a-z0-9_\- $]+(\/[a-z0-9_\- ]+)*$/ui),
            }),

            // Dynamic topics are not implemented atm, to avoid writing resubscribe logic.
            // z.object({
            //     type: z.literal('var'),
            //     var: z.string().min(1),
            // }),
        ]),

        /**
         * the var that will hold the received payload's timestamp.
         * useful for detecting when a payload was received even if the contents weren't changed.
         *
         * this will be pending until a payload is received.
         *
         * optional
         */
        tsVar: optionalVarNameSchema,

        parseConfig: z.object({
            type: z.enum([ 'num', 'str' ]).default('str'), // only string supported for now

            /**
             * the variable to store the parsed value in.
             *
             * this is in the parseConfig because in the future we might have something that parses a JSON and stores multiple values.
             */
            var: varNameSchema,
        }),

        qos: z.union([
            z.literal(0),
            z.literal(1),
            z.literal(2),
        ]).default(0),
    }),
});
