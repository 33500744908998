var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"entity-detail",class:{ 'pb-4': !_vm.open },style:({
        height: '100%',
        minHeight: '100%',
        width: _vm.entityStyling?.width,
    })},[_c('VCard',{style:({
            zIndex: 0,
            height: '100%',
            display: 'flex',
            minHeight: '100%',
            flexDirection: 'column',
            marginTop: `${_vm.entityStyling?.margin?.top ?? 0}px`,
            marginLeft: `${_vm.entityStyling?.margin?.left ?? 8}px`,
            marginRight: `${_vm.entityStyling?.margin?.right ?? 8}px`,
            marginBottom: `${_vm.entityStyling?.margin?.bottom ?? 0}px`,
        }),attrs:{"color":_vm.open ? '#FFF0' : '#f7f7f7ff',"elevation":_vm.open ? 0 : 2}},[(_vm.useEntityDetailTabs)?_c('VTabs',{staticClass:"elevation-2 edc-tabs",staticStyle:{"flex":"0"},attrs:{"value":_vm.activeTabIdx,"flat":"","background-color":"primary","slider-color":_vm.activeTabIdx >= 0 ? 'secondary' : '#0000'}},[(_vm.showBackBtn)?_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"ma-2 rounded",attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('back')}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1)],1):_vm._e(),_vm._l((_vm.tabs),function(tab,idx){return _c('VTab',_vm._b({key:idx,staticClass:"edc-tab",on:{"change":function($event){return _vm.$emit('update:activeTabIdx', idx)}}},'VTab',tab.attrs,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_vm._v(" "+_vm._s(tab.label)+" ")],1)}),_c('VSpacer')],2):_vm._e(),_c('Portal',{attrs:{"to":"app-bar-extra-fab","order":-1}},[_c('v-chip',{staticClass:"change-detection mx-1 align-self-center",class:{ hasChanges: _vm.hasChanges && !_vm.$vuetify.breakpoint.smAndDown },style:({
                    cursor: _vm.hasChanges ? 'pointer' : 'default',
                }),attrs:{"color":"warning","ripple":""},on:{"click":function($event){_vm.changesDialogOpen = true}}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(" mdi-alert-circle ")])],1),_vm._v(" Changes Detected ")],1)],1),_c('FabBannerBtn',{staticClass:"edc-edit",attrs:{"enabled":!_vm.editing && _vm.canEdit,"color":"accent","tooltip":_vm.$messages.edit,"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$emit('update:editing', true)}}}),_c('FabBannerBtn',{staticClass:"edc-fork",attrs:{"enabled":!_vm.forking && _vm.canFork,"color":"secondary","loading":_vm.busy,"disabled":_vm.busy,"icon":"mdi-source-branch"},on:{"click":function($event){return _vm.$emit('fork')}}}),_c('FabBannerBtn',{staticClass:"edc-save",attrs:{"order":1,"enabled":_vm.editing && _vm.allowSaveFab,"color":"secondary","loading":_vm.busy,"disabled":_vm.busy,"icon":"mdi-content-save"},on:{"click":function($event){return _vm.$emit('save')}}}),_c('FabBannerBtn',{staticClass:"edc-cancel",attrs:{"order":2,"enabled":_vm.editing && _vm.allowCancelFab,"color":"error","icon":"mdi-close","disabled":_vm.busy,"tooltip":_vm.$messages.cancel},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('update:editing', false)}}}),_c('div',{staticClass:"slider-container-edc custom-scroll",style:({ paddingBottom: _vm.open ? '' : '.05in' })},[_c('Transition',{key:_vm.$route.name,attrs:{"name":_vm.transitionName}},[_c('RouterView')],1)],1),_vm._t("actions")],2),_c('PortalTarget',{attrs:{"name":"edc-extra"}}),_c('ChangesDialog',{attrs:{"entityChangesDetail":_vm.entityChangesDetail},model:{value:(_vm.changesDialogOpen),callback:function ($$v) {_vm.changesDialogOpen=$$v},expression:"changesDialogOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }