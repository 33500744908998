import { z } from 'zod';
import { varConditionSchema } from './conditional.zod';
import { varpConfigBase } from './var-provider.zod';
import { varNameSchema } from './var.zod';

const tabValidationEvaluation = <T>(typeParameter: z.ZodType<T>) => z.object({
    resultingValue: typeParameter,
    disable: varConditionSchema,
    activate: varConditionSchema,
    display: z.object({
        info: varConditionSchema,
        error: varConditionSchema,
        warning: varConditionSchema,
        success: varConditionSchema,
    }),
});

const tabLabelValidationTypeConfig = z.object({
    validationType: z.literal('tabLabel'),
    tabConfigs: z.array(tabValidationEvaluation(z.string())),
});

const tabIndexValidationTypeConfig = z.object({
    validationType: z.literal('tabIndex'),
    tabConfigs: z.array(tabValidationEvaluation(z.number())),
});

export const tabGroupControllerProviderConfigSchema = varpConfigBase.extend({
    inputs: z.array(varNameSchema),
    type: z.literal('tabGroupController'),
    attrs: z.object({
        payloadVarName: varNameSchema,
        /** if true the validation for which tab to show will run as soon as the varp provider gets spun up */
        shouldRunOnStartup: z.boolean(),
        /** set of conditions that define how to control the look and function of a tab in a tab group */
        validationConfig: z.discriminatedUnion('validationType', [ tabIndexValidationTypeConfig, tabLabelValidationTypeConfig ]),
    }),
});
