import { GetRefreshTokenResult } from '../types';
import { apiTrpcHttpClient } from '../../util/api-trpc';

/**
 * Gets the available contexts
 */
export function getAvailableContexts () {
    return apiTrpcHttpClient.auth.partialToken.contexts.query({});
}

/**
 * Sets the context
 */
export function setContext (contextId: string, partOrHasuraToken: 'partialJwt' | 'hasuraJwt') {
    if (partOrHasuraToken === 'partialJwt') {
        return apiTrpcHttpClient.auth.partialToken.setContext.mutate({
            contextId,
        });
    } else {
        return apiTrpcHttpClient.auth.token.changeContext.query({
            contextId,
        });
    }
}

/**
 * Gets a refresh token
 */
export function getRefreshToken (): Promise<GetRefreshTokenResult> {
    return apiTrpcHttpClient.auth.token.refresh.query();
}

/**
 * Revokes a refresh token
 */
export function refreshTokenRevoke () {
    return apiTrpcHttpClient.auth.token.revoke.mutate();
}

/**
 * Logs into the Auth Service using a Local provider
 */
export function providerLocalLogin (email: string, password: string) {
    return apiTrpcHttpClient.auth.provider.local.login.mutate({
        email: email.toLowerCase().trim(),
        password,
    });
}
