// lovingly borrowed from https://stackoverflow.com/a/2117523/2124254
// I'm deciding to use this instead of the `uuid` package because it's smaller, and I hope we can use this in places
// other than rete stuff, like cells.
/** use this for smaller IDs that don't get stored in the db as a UUID */
export function getUID (): string {
    const crypto: Crypto = typeof window === 'undefined' ? require('crypto') : window.crypto;
    const bytes = crypto.getRandomValues(new Uint8Array(8));
    const array = Array.from(bytes);
    const hexPairs = array.map(b => b.toString(16).padStart(2, '0'));
    return hexPairs.join('');
}
